import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { AddModuleToProgramme, GetProgramAssignedOrNotAssignedListCancelToken, GetProgramAssignedOrNotAssignedListFilter } from "../../../../services/ProgramService";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Str from "../../../common/Str";
import { TrimText } from "../../../common/TrimText";
import Tablefilter from "../../../common/Tablefilter";
import { RenderModuleType, RenderModuleStatus } from "../../../../utils/CommonGroupingItem";
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import { handleTableScroll } from "../../../../utils/commonFunction";
import axios from "axios";
import HtmlParser from "react-html-parser";

const AvailableTables = ({ id, data, refresh, loading }) => {
  const statusArr = {
    "Draft" : `<span class="table red">DRAFT </span> `,
    "New" : `<span class="table skyblue">NEW</span>`,
    "Final" : ` <span class="table green">FINAL</span>`,
    "Approved" : `<span class="table yellowshade">APPROVED</span>`,
    "Inactive" : `<span class="table blue">INACTIVE</span>`,
  }
  const [searchAvailable, setSearchAvailable] = useState("");
  const [duration, setDurationAvailable] = useState({arr:[], checkObj:{}});
  const [filterDataAvailable, setFilterDataAvailable] = useState([]);
  const [moduleStatusAvailable, setModuleStatusAvailable] = useState({ arr: [], checkObj: {} });

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await GetProgramAssignedOrNotAssignedListFilter(id,"available",filterSource.token);
        if(res.status==200){
          setFilterDataAvailable(res.data?.types);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }
    
    getFilters();
    return () => {
      filterSource.cancel('Component unmounted');      
    };
  }, []);

  
  
  const columns = useMemo(() => [
    {
      name: "Portal Name",
      selector: "Portal",
      sortable: true,
      cell: (row) => (
        <span
            title={row.Portal}
            className="as-text-blue curser feature-name"
          onClick={() => addModuleToTable(row.Id)}
          >
          <span className="textLimit100">{row.Portal.trim() ? row.Portal : "-"}</span>
        </span>
      ),
    },
    {
      name: "Code",
      selector: "Code",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          {row.Code ? (
            <span title={row.Code} className="overflow-ellipsis2">
            {TrimText(row.Code, 15)}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },

    {
      name: "Marketing Name",
      selector: "Marketing_Name",
      sortable: true,
      cell: (row) => (
        <span title={row.Marketing_Name} className="overflow-ellipsis2">
          {row.Marketing_Name ? TrimText(row.Marketing_Name, 20) : "-"}
          </span>
        ),
    },
    {
      name: "Duration (Weeks)",
      selector: "Duration",
      sortable: true,
      cell: (row) => row.Duration && row.Duration.toString() ? <div title={row.Duration}>{row.Duration}</div> : "-"
    },
    {
      name: "Status",
      selector: "module_status",
      sortable: true,
      cell: (row) => <span>{HtmlParser(statusArr[row.module_status])}</span>,
    },
    {
      name: "Term",
      selector: "term",
      sortable: true,
      cell: (row) => <span>{row.term}</span>,
    },
    {
      name: "Year",
      selector: "year",
      sortable: true,
      cell: (row) => <span>{row.year}</span>,
    },
    {
      name: "#Programmes",
      selector: "Programmes",
      sortable: true,
      cell: (row) => (row.Programmes.toString() ? <div title={row.Programmes}>{row.Programmes}</div> : "-"),
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
                <button
                  className="btn btn-primary rounded-circle"
              title="Add"
              onClick={() => addModuleToTable(row.Id)}
                  >
              <i className="fal fa-plus"></i>
                  </button>
          </div>
        </div>
      ),
    },
  ]);

  const addModuleToTable = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "confirm",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        AddModuleToProgramme({ PId: id, MId: ID })
          .then((res) => {
            Swal.fire("Added!", `${res?.data}`, "success");
            refresh();
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const dataToRenderAvailable = () => {
    let updatedData = [];
    let allData = data;
    if (searchAvailable.length) {
      let tempCode = allData.filter((item) => {
        let includes = item.Code && item.Code.toString()
          .toLowerCase()
          .includes(searchAvailable.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let temp = allData.filter((item) => {
        let includes = item.Portal && item.Portal.toString()
            .toLowerCase()
            .includes(searchAvailable.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDuration = allData.filter((item) => {
        let includes = item.Duration && item.Duration.toString()
            .toLowerCase()
            .includes(searchAvailable.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempMname = allData.filter((item) => {
        let includes =
          item.Marketing_Name &&
          item.Marketing_Name.toLowerCase().includes(searchAvailable.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempType = allData.filter((item) => {
        let includes =
          item.Module_Type &&
          item.Module_Type.toLowerCase().includes(searchAvailable.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempdata = [
        ...temp,
        ...tempType,
        ...tempDuration,
        ...tempCode,
        ...tempMname,
      ];
      let unique = [...new Set(tempdata)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (duration.arr.length) {
      let tempData = updatedData;
      let tempSearch = tempData.filter((item) => {
        const startsWith = duration.arr.find((weeks) => {
          if (weeks === "1-4") {
            if (Number(item.Duration) > 1 && Number(item.Duration) <= 4)
              return item;
          }
          if (weeks === "5-8") {
            if (Number(item.Duration) > 4 && Number(item.Duration) <= 8)
              return item;
          }
          if (weeks === "9-12") {
            if (Number(item.Duration) > 8 && Number(item.Duration) <= 12)
              return item;
          }
          if (weeks === "> 12") {
            if (Number(item.Duration) > 12) return item;
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (moduleStatusAvailable.arr.length) {
      let tempStudyType = updatedData;
      let tempSearch = tempStudyType.filter((item) => {
        const startsWith = moduleStatusAvailable.arr.find(function (post, index) {
          if (
            post.toLowerCase() ==
            (item.module_status ? item.module_status.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }
    if (searchAvailable.length || duration.arr.length || moduleStatusAvailable.arr.length) {
      return updatedData;
    } else {
      return data;
    }
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearchAvailable(value);
  };

  const resetFilter = () => {
    setSearchAvailable("");
    setModuleStatusAvailable({ arr: [], checkObj: {} })
    setDurationAvailable({ arr: [], checkObj: {} })
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRenderAvailable();
    const header = [
      "Portal Name",
      "Code",
      "Marketing Name",
      "Duration (Weeks)",
      "Status",
      "Year",
      "Term",
      "Programmes",
    ];

    data = data?.map((row) => ({
      ...row,
      "Portal Name": row?.Portal ? row.Portal : "-",
      Code: row.Code ? row?.Code : "-",
      "Marketing Name": row?.Marketing_Name ? row?.Marketing_Name : "-",
      Duration: row?.Duration ? row.Duration : "-",
      Status: row.module_status ? row.module_status : "-",
      Type: row.Module_Type ? RenderModuleType(row.Module_Type).text : "-",
      Year: row.year ? row.year + "-" + row.number : "-",
      Term: row.term ? row.term : "-",
      Topics: row.topics ? row.topics : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Portal Name"],
          row["Code"],
          row["Marketing Name"],
          row.Duration,
          row.module_status,
          row["Year"],
          row["Term"],
          row["Programmes"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-available-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-available-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-available-main"
                        onChange={handleSearchFilter}
                        value={searchAvailable}
                      />
                    </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Duration"
                      optionArr={
                        [
                          { value: "1-4", label: "1-4" },
                          { value: "4-8", label: "4-8" },
                          { value: "8-12", label: "8-12" },
                          { value: "> 12", label: "> 12" }
                        ]
                      }
                      state={duration}
                      setState={setDurationAvailable}
                      stopOptionSorting={true}
                      uniqueId="availableDuration"
                    />
                    <Tablefilter
                      filterName="Status"
                      uniqueId="Available"
                      optionArr={[
                        {
                          value: "Draft",
                          label: "Draft",
                        },
                        {
                          value: "New",
                          label: "New"
                        },
                        {
                          value: "Final",
                          label: "Final"
                        },
                        {
                          value: "Approved",
                          label: "Approved"
                        },
                        {
                          value: "Inactive",
                          label: "Inactive"
                        },
                      ]}
                      state={moduleStatusAvailable}
                      setState={setModuleStatusAvailable}
                      stopOptionSorting={true}
                      renderLabelFunction={RenderModuleStatus}
                    />
                  </div>
                </div>

                {/*end of learning method*/}
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={() => resetFilter()}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Available Modules");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Available Modules");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Available Modules");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>

        {loading ? <SkeletonTicketList /> : <DataTable
            data={dataToRenderAvailable()}
            defaultSortField="Portal"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            filterData={filterDataAvailable}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
      </div>
    </div>
  );
};

export default AvailableTables;
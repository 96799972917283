import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Str from "../../../common/Str";
import {
  getCommonContent,
  GetProgramAssignedOrNotAssignedList,
  GetProgramAssignedOrNotAssignedListCancelToken,
  GetProgramAssignedOrNotAssignedListFilter,
  RemoveModuleFromProgramme,
} from "../../../../services/ProgramService";
import Swal from "sweetalert2";
import { TrimText } from "../../../common/TrimText";
import { Link } from "react-router-dom";
import AvailableTables from "./AvailableTables";
import PermissionsGate from "../../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import Tablefilter from "../../../common/Tablefilter";
import { RenderModuleType, RenderModuleStatus } from "../../../../utils/CommonGroupingItem";
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import { handleTableScroll } from "../../../../utils/commonFunction";
import axios from "axios";
import HtmlParser from "react-html-parser";

const AssignedTable = () => {
  const statusArr = {
    "Draft" : `<span class="table red">DRAFT </span> `,
    "New" : `<span class="table skyblue">NEW</span>`,
    "Final" : ` <span class="table green">FINAL</span>`,
    "Approved" : `<span class="table yellowshade">APPROVED</span>`,
    "Inactive" : `<span class="table blue">INACTIVE</span>`,
  }
    const history = useHistory();
  const { id } = useParams();
  const [searchAssigned, setSearchAssigned] = useState("");

  const [durationAssigned, setDurationAssigned] = useState({arr:[], checkObj:{}});
  const [filterDataAssigned, setFilterDataAssigned] = useState([]);
  const [data, setData] = useState();
  const [availableData, setAvailableData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableLoading, setAvailableLoading] = useState(false);
  const [moduleStatus, setModuleStatus] = useState({ arr: [], checkObj: {} });

  useEffect(() => {
    handleTableScroll()
  }, [loading])


  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await GetProgramAssignedOrNotAssignedListFilter(id,"assigned",filterSource.token);
        if(res.status==200){
          setFilterDataAssigned(res.data?.types);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);          
        }
      }
    }
    
    getFilters();
    return () => {
      filterSource.cancel('Component unmounted');      
    };
  }, []);

  useEffect(() => {
      const cancelTokenSources = [];

      const getProgramsList = async () => {
        setLoading(true)
    
        cancelTokenSources.forEach(source => {
          source.cancel('New request made');
        });
    
        const source = axios.CancelToken.source();
        cancelTokenSources.push(source);

        try {
          const res = await GetProgramAssignedOrNotAssignedListCancelToken({ PId: id, type: "assigned" }, source.token);
          if (res.status === 200) {
            setLoading(false)
            setData(res.data.programs);
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
            setLoading(false)
          }
        }
      };
    
      getProgramsList();
  
      return () => {
        cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
        });
      };
  }, [refresh]);
  useEffect(() => {
    const cancelTokenSources = [];
    const getList = async () =>{
      setAvailableLoading(true)
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetProgramAssignedOrNotAssignedListCancelToken({ PId: id, type: "available" }, source.token);
        if (res.status === 200) {
          setAvailableLoading(false);
          setAvailableData(res.data.programs)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setAvailableLoading(false)
        }
      }
    }

    getList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [refresh]);

  const givenPermsisionsAssigned = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["pmview"], permissions: givenPermsisionsAssigned });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  const handlerefresh = () => {
    setRefresh(!refresh);
  };
  
  const columns = useMemo(() => [
    {
      name: "Portal Name",
      selector: "Portal",
      sortable: true,
      cell: (row) => (
        <PermissionsGate
          scopes={["pmcpmedit"]}
          RenderError={() => <span className="feature-name"> <span className="textLimit100">{row.Portal}</span></span>}
        >
          <Link
            to={`/program-management-center/modules/module/open/${row.Id}`}
            title={row.Portal}
            className="as-text-blue curser feature-name"
          >
            <span title={row.Portal} className="textLimit100">{row.Portal}</span>
          </Link>
        </PermissionsGate>
      ),
    },
    {
      name: "Code",
      selector: "Code",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          {row.Code ? <span title={row.Code} className="overflow-ellipsis2">
            {TrimText(row.Code, 15)}
          </span> : "-"}
        </div>
      ),
    },

    {
      name: "Marketing Name",
      selector: "Marketing_Name",
      sortable: true,
      cell: (row) => (
        <span className="overflow-ellipsis2" title={row.Marketing_Name}>
          {row.Marketing_Name ? TrimText(row.Marketing_Name, 20) : "-"}
          </span>
        ),
    },
    {
      name: "Duration (Weeks)",
      selector: "Duration",
      sortable: true,
      cell: (row) => row.Duration && row.Duration.toString() ? <div title={row.Duration}>{row.Duration}</div> : "-"
    },
    {
      name: "Status",
      selector: "module_status",
      sortable: true,
      cell: (row) => <span>{HtmlParser(statusArr[row.module_status])}</span>,
    },
    {
      name: "Term",
      selector: "term",
      sortable: true,
      cell: (row) => <span>{row.term}</span>,
    },
    {
      name: "Year",
      selector: "year",
      sortable: true,
      cell: (row) => <span>{row.year}</span>,
    },
    {
      name: "#Programmes",
      selector: "Programmes",
      sortable: true,
      cell: (row) => (row.Programmes.toString() ? <div title={row.Programmes}>{row.Programmes}</div> : "-"),
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["pmcpmedit"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                to={`/program-management-center/modules/module/open/${row.Id}`}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
                <PermissionsGate
              scopes={["pmdelete"]}
                  RenderError={() => (
                    <button
                      className="btn btn-danger rounded-circle"
                      title="Delete"
                      disabled
                    >
                      <i className="fal fa-trash-alt"></i>
                    </button>
                  )}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row.Id)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveModuleFromProgramme({ PId: id, MId: ID })
          .then((res) => {
            Swal.fire("Deleted!", "Your module has been removed.", "success");
            handlerefresh();
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (searchAssigned.length) {
      let tempCode = allData.filter((item) => {
        let includes = item.Code && item.Code.toString()
          .toLowerCase()
          .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let temp = allData.filter((item) => {
        let includes = item.Portal && item.Portal.toString()
            .toLowerCase()
            .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDuration = allData.filter((item) => {
        let includes = item.Duration && item.Duration.toString()
          .toLowerCase()
          .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempTopics = allData.filter((item) => {
        let includes = item.topics && item.topics
          .toString()
          .toLowerCase()
          .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgramme = allData.filter((item) => {
        let includes = item.Programmes && item.Programmes.toString()
            .toLowerCase()
            .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempMname = allData.filter((item) => {
        let includes =
          item.Marketing_Name &&
          item.Marketing_Name.toLowerCase().includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempType = allData.filter((item) => {
        let includes =
          item.Module_Type &&
          item.Module_Type.toLowerCase().includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempdata = [
        ...temp,
        ...tempTopics,
        ...tempProgramme,
        ...tempType,
        ...tempDuration,
        // ...tempId,
        ...tempCode,
        ...tempMname,
      ];
      let unique = [...new Set(tempdata)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (durationAssigned.arr.length) {
      let tempData = updatedData;
      let tempSearch = tempData.filter((item) => {
        const startsWith = durationAssigned.arr.find((weeks) => {
          if (weeks === "1-4") {
            if (Number(item.Duration) > 1 && Number(item.Duration) <= 4)
              return item;
          }
          if (weeks === "5-8") {
            if (Number(item.Duration) > 4 && Number(item.Duration) <= 8)
              return item;
          }
          if (weeks === "9-12") {
            if (Number(item.Duration) > 8 && Number(item.Duration) <= 12)
              return item;
          }
          if (weeks === "> 12") {
            if (Number(item.Duration) > 12) return item;
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (moduleStatus.arr.length) {
      let tempStudyType = updatedData;
      let tempSearch = tempStudyType.filter((item) => {
        const startsWith = moduleStatus.arr.find(function (post, index) {
          if (
            post.toLowerCase() ==
            (item.module_status ? item.module_status.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }
    if (searchAssigned.length || durationAssigned.arr.length || moduleStatus.arr.length) {
      return updatedData;
    } else {
      return data;
    }
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearchAssigned(value);
  };

  const resetFilter = () => {
    setSearchAssigned("");
    setModuleStatus({ arr: [], checkObj: {} })
    setDurationAssigned({ arr: [], checkObj: {} })
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Portal Name",
      "Code",
      "Marketing Name",
      "Duration (Weeks)",
      "Status",
      "Year",
      "Term",
      "Programmes",
    ];

    data = data?.map((row) => ({
      ...row,
      "Portal Name": row?.Portal ? row.Portal : "-",
      Code: row.Code ? row?.Code : "-",
      "Marketing Name": row?.Marketing_Name ? row?.Marketing_Name : "-",
      Duration: row?.Duration ? row.Duration : "-",
      Status: row.module_status ? row.module_status : "-",
      Type: row.Module_Type ? RenderModuleType(row.Module_Type).text : "-",
      Year: row.year ? row.year + "-" + row.number : "-",
      Term: row.term ? row.term : "-",
      Topics: row.topics ? row.topics : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Portal Name"],
          row["Code"],
          row["Marketing Name"],
          row.Duration,
          row.Topics,
          row["module_status"],
          row.year,
          row.term,
          row["Programmes"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-assigned-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                <div
                  id="assessment-table-assigned-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-assigned-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-assigned-main"
                        onChange={handleSearchFilter}
                        value={searchAssigned}
                      />
                    </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Duration"
                      optionArr={
                        [
                          { value: "1-4", label: "1-4" },
                          { value: "4-8", label: "4-8" },
                          { value: "8-12", label: "8-12" },
                          { value: "> 12", label: "> 12" }
                        ]
                      }
                      state={durationAssigned}
                      setState={setDurationAssigned}
                      stopOptionSorting={true}
                      uniqueId="assignedDuration"
                    />
                    <Tablefilter
                      filterName="Status"
                      optionArr={[
                        {
                          value: "Draft",
                          label: "Draft" 
                        },
                        {
                          value: "New",
                          label: "New"
                        },
                        {
                          value: "Final",
                          label: "Final"
                        },
                        {
                          value: "Approved",
                          label: "Approved"
                        },
                        {
                          value: "Inactive",
                          label: "Inactive"
                        },
                      ]}
                      state={moduleStatus}
                      setState={setModuleStatus}
                      stopOptionSorting={true}
                      renderLabelFunction={RenderModuleStatus}
                    />
                  </div>
                </div>
                {/*end of learning method*/}
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={() => resetFilter()}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                      exportData("xlsx", "Assigned Modules");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                      exportData("csv", "Assigned Modules");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                      exportData("pdf", "Assigned Modules");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate
                  RenderError={() => (
                    <button className="btn btn-primary" title="Assign Module" disabled>
                      <i className="fal fa-plus"></i>Assign Module
                    </button>
                  )}
                  scopes={["pmadd"]}
                >
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#viewCourses"
                    title="Assign Module"
                  >
                    <i className="fal fa-plus"></i>Assign Module
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        {loading ? <SkeletonTicketList />
          : <DataTable
            data={dataToRender()}
            defaultSortField="Portal"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
      </div>
      {/* Modal to show table of child courses */}
      <div
        className="topic-add-modal modal fade"
        id="viewCourses"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Assign Module</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
            <div className="modal-body">
              <AvailableTables
                data={availableData}
                refresh={handlerefresh}
                id={id}
                filterData={filterDataAssigned}
                loading={availableLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignedTable;

import React from 'react';
import { FormField } from '../common/FormFields';

const formatLabel = (field) => {
  // Convert camelCase to space-separated and capitalize the first letter
  const label = field.replace(/([A-Z])/g, ' $1').trim();
  return label.charAt(0).toUpperCase() + label.slice(1);
};

/**
* SectionColorOverrideComponent
* 
* This component renders a toggle switch that allows the user to override colors for specific fields.
* When the toggle is enabled, it displays a set of color fields that can be customized.
* 
* Props:
* - prefix (string): A string to prefix each field name, allowing dynamic handling of multiple sets of fields (e.g., "school_", "department_").
* - formik (object): The formik object that handles form state, values, and handlers.
* - fields (array): An array of strings representing the field names to be overridden. The component will automatically assume 
*   that these are color fields and will apply defaults for required and type attributes.
* 
* Example Usage:
* 
* <SectionColorOverrideComponent
*   prefix="school_"
*   formik={formik}
*   fields={["accentColorOne", "textColorOne", "textColorTwo", "backgroundColor"]}
* />
*/

const SectionColorOverrideComponent = ({ prefix, formik, fields }) => {
  // Default settings for fields
  const defaultFieldConfig = {
    color: true,
    required: false,
    type: "color"
  };
  
  return (
    <>
    <div
    className="d-flex my-20"
    title="Include content from PMC on the website."
    >
    <label className="mb-0">
    Override Colors
    <i className="fal fa-info-circle grade-icon ml-2"></i>
    {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
    </label>
    <div className="toggle-switch ml-2">
    <label className="switch">
    <input
    type="checkbox"
    checked={formik.values[`${prefix}override_color`] == 1}
    onChange={(e) => {
      formik.setFieldValue(
        `${prefix}override_color`,
        e.target.checked ? 1 : 0
      );
    }}
    />
    <span className="slider slider-round"></span>
    </label>
    </div>
    </div>
    {formik.values[`${prefix}override_color`] == 1 ? (
      <div className="row mt-2">
      {fields.map((field, index) => (
        <div key={index} className="col-md-3 col-sm-6 mb-3"> {/* Adjusted to 3 columns per input to fit 4 per row */}
        <FormField
        col_lg={9}
        field={{
          label: formatLabel(field),
          name: `${prefix}${field}`,
          ...defaultFieldConfig // Apply default settings
        }}
        formik={formik}
        />
        </div>
      ))}
      </div>
    ) : null}
    </>
  );
};

export default SectionColorOverrideComponent;

import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { TermPeriods } from "../../systemadministration/DataReactSelect";
import { useFormik } from "formik";
import Select from "react-select";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Papa from "papaparse";
import {
  GetSchoolList,
  GetProgramDetailCancelToken,
  GetProgrammePageDetailCancelToken,
  GetCampusLocationDropdown,
  GetPagesList,
  GetCategoryListStatic,
  GetPopUpListStatic,
  getPagesListForPmcFilters,
  getBrandTemplateList,
  getAllPmcProgrammesDropdown,
  AddAndUpdateProgrammePageCancelToken,
  GenerateFactSheet,
} from "../../../services/ProgramService";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import {
  RenderProgrammeOrCourseType,
  RenderProgrammeType,
} from "../../../utils/CommonGroupingItem";
// import { RenderPMCStatus } from "../../../utils/CommonStatusItems";
import HtmlParser from "react-html-parser";
import axios from "axios";
import { GetNetsuiteProductListCancelToken } from "../../../services/ProgrammeServices";
import {
  checkIfImageExists,
  modifiyOptionsForSelect,
} from "../../../utils/commonFunction";
import DataTableComponent from "../../common/DataTableComponent";
import { TrimText } from "../../common/TrimText";
import { downloadURL, IMAGE_URL } from "../../../utils/Constants";
import $, { error } from "jquery";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";
import PermissionsGate from "../../../utils/permissionGate";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import {
  LayoutHeaderStatus,
  RenderPMCPageStatus,
  RenderPMCStatus,
  RenderPMCStatusCol,
  RenderPageBrands,
} from "../../../utils/CommonStatusItems";
import { FileUploadField } from "../../common/FormFields";
import { duration } from "moment";
import { download } from "../../../utils/FileDownload";
import { legacy_createStore } from "redux";
import { getPagesFilters, getPagesList, getPagesListWithToken } from "../../../services/BrandTemplateServices";

const statusArr = [
  {
    value: "Unpublished",
    label: HtmlParser(
      `<span className="red" title="Unpublished">Unpublished</span>`
    ),
  },
  {
    value: "Draft",
    label: HtmlParser(`<span className="blue" title="Draft">Draft  </span> `),
  },
  {
    value: "Published",
    label: HtmlParser(
      `<span className="green" title="Published">Published</span>`
    ),
  },
];

const PublishstatusArr = [
  {
    value: "Draft",
    label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `),
  },
  {
    value: "Published",
    label: HtmlParser(
      `<span className="blue" title="Published">Published</span>`
    ),
  },
  {
    value: "Unpublished",
    label: HtmlParser(
      `<span className="blue" title="Unpublished">Unpublished</span>`
    ),
  },
];

const learningMethodOptionArr = [
  {
    // label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `)
    label: "Full Time,Hybrid",
    value: "full",
  },
  {
    label: "Full Time,Online",
    value: "fulltimeonline",
  },
  {
    label: "Part Time,Online",
    value: "parttimeonline",
  },
  {
    label: "Part Time,Online",
    value: "parttimeonline",
  },
  // {
  //   value: "Draft",
  //   label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `)
  // },
  // {
  //   value: "New",
  //   label: HtmlParser(`<span className="skyblue" title="New"> New </span>`)
  // },
  // {
  //   value: "Final",
  //   label: HtmlParser(` <span className="green" title="Final">Final</span>`)
  // },
  // {
  //   value: "Approved",
  //   label: HtmlParser(`<span className="yellowshade" title="Approved">Approved</span>`)
  // },
  // {
  //   value: "Inactive",
  //   label: HtmlParser(`<span className="blue" title="Inactive">Inactive</span>`)
  // },
  // {
  //   value: "Published",
  //   label: HtmlParser(`<span className="blue" title="Published">Published</span>`)
  // },
  // {
  //   value: "Unpublished",
  //   label: HtmlParser(`<span className="blue" title="Unpublished">Unpublished</span>`)
  // },
];

const NQF_LevelArr = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
];

const title = {
  siteDomain: "This is the URL of the website",
  branch: "If the website is the production, development or staging branch. If this is set to production, all pages and qualifications will be filtered by where published status is set to published, if set to development it will show both published and unpublished items.",
  status: "This determines if the website is live or not. If set to archived the website will not load any data.",
  tagline: " The tagline is a short line of text that aims to communicate the site's main focus. Taglines are usually eight to 12 words long",
  siteIcon: "The site icon is a small, 16x16 pixel icon used on web browsers to represent a website or a web page. It is commonly displayed on tabs at the top of a web browser, but they're also found on the browser's bookmark bar, history and in search results, alongside the page url.",
  linkedBrandTemplate: "This is the brand template linked to the website and determains the style of the website as well as the content displayed."
}
const helpText = {
  marketing: 'The name of the programme page' ,
  linkedPMC: 'The linked PMC Programme links data from the PMC programme to the created programme', 
  status: 'The status determines the visibility of the programme. When set to draft the page will be viewable on the development websites.' 
}

const helpText2 = {
  linkedPMC: 'The help text must read “The linked PMC Programme links data from the PMC programme to the created programme',
  status : 'The status determines the visibility of the programme. When set to draft the page will be viewable on the development websites.',
  assignedBrand : 'Linked brand template where the page will be assigned. The page will be assigned to all sites connected to the specified brand templates',
  duration : 'The programme duration in text e.g. 1 day, 4 weeks, 1 year, 2-4 years.',
  banner_image : 'The image show on the qualification card and on the qualification banner. The optimal resolution is 1440px x 510px'
}
const ProgrammePageDetails = ({ pageData, pageLoading, setUpdateData }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const { id, type } = useParams();
  const [programId, setProgramID] = useState(id);
  const [disabled, setDisabled] = useState(false);
  const [AssignData, setAssignData] = useState();
  const [editorState1, setEditorState1] = useState("");
  const [subjectContent, setSubjectContent] = useState("");
  const [factsheet,setFactsheet] = useState("")
  const [admissionReq, setAdmissionReq] = useState("");
  const [accreditation, setAccreditation] = useState();
  const [articulationOption, setArticulationOption] = useState("");
  const [url,setUrl] = useState("")
  const [studyKit, setStudyKit] = useState("");
  const [careerOpportunity, setCareerOpportunity] = useState("");
  const [hardwareReq, setHardwareReq] = useState("");
  const [duration, setDuration] = useState("");
  const [regulatoryInfo, setRegulatoryInfo] = useState("");
  const [impNotes, setImpNotes] = useState("");
  const [learningMethodArr, setLearningMethodArr] = useState([]);
  const [editorToShow, setEditorToShow] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [campusLocList, setCampusLocList] = useState([]);
  const [netsuiteProdList, setNetsuiteProdList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [popupList, setPopupList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [allpageListData, setAllPageListData] = useState([]);
  const [assignPageList, setAssignPageList] = useState([]);
  const [assignPageLoading, setAssignPageLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState({ arr: [], checkObj: {} });
  const [pageBrand, setPageBrand] = useState({ arr: [], checkObj: {} });
  const [PageFilterData, setPageFilterdata] = useState({
    status: [],
    BrandList: [],
  });

  const [assignPageTableState, setAssignPageTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "PageTitle",
    sortOrder: "asc",
  });

  const [assignPageSearch, setAssignPageSearch] = useState("");
  const [assignPageTotalRows, setAssignPageTotalRows] = useState(0);
  const [assignedPage, setAssignedPage] = useState({});
  const [buttonLoading,setButtonLoading] = useState(false);
  const [articulationOptionList, setArticulationOptionList] = useState([]);
  const [articulationOptionLoading, setArticulationOptionLoading] = useState(false);
  const [articulationOptionSearch, setArticulationOptionSearch] = useState("");
  const [articulationOptionTotalRow, setArticulationOptionTotalRow] = useState("");
  const [articulationOptionTableState, setArticulationOptionTableState] =
    useState({
      page: 1,
      perPage: 10,
      sortKey: "name",
      sortOrder: "asc",
    });
  const [artStatus, setArtStatus] = useState({ arr: [], checkObj: {} });
  const [artProgType, setArtProgType] = useState({ arr: [], checkObj: {} });
  const [articulationOptionFilterData, setArticulationOptionFilterData] = useState({ status: [], programmeType: [], type: [] });
  const [artType, setArtType] = useState({ arr: [], checkObj: {} });
  const [articulationAssignedTableState, setArticulationAssignedTableState] =
    useState({
      page: 1,
      perPage: 10,
      sortKey: "name",
      sortOrder: "asc",
    });
  const [assignedDataList, setAssignedDataLis] = useState([])
  const [articulationAssignedTotalRow, setArticulationAssignednTotalRow] = useState("");
  const [assignedArticulationOptionList, setAssignedArticulationOptionList] = useState([]);
  const [assignedArticulationSearch, setAssignedArticulationSearch] = useState("");
  const [assignedArticulationOptionLoading, setAssignedArticulationOptionLoading,] = useState(false);
  const [assignedStatus, setAssignedStatus] = useState({ arr: [], checkObj: {}, });
  const [assignedProgType, setAssignedProgType] = useState({ arr: [], checkObj: {}, });
  const [assignedType, setAssignedType] = useState({ arr: [], checkObj: {} });
  const [assignedArticulationOptionFilterData, setAssignedArticulationOptionFilterData,] = useState({ AssignStatus: [], AssignProgrammeType: [], AssignType: [] });
  const dropDownValues = useSelector((state) => state.registerDropdownValues);
  const [programmeList, setProgrammeList] = useState([]);
  const [next, setNext] = useState(false)
  const [detail, setDetail] = useState([])
  const [programmeDetails, setProgrammeDetails] = useState([])
  const [pmc_id, setPMC_id] = useState("")
  const [isValuesSet, setIsValuesSet] = useState(false);
  const [programName, setProgrammName] = useState("")
  const [netsuite_product, setNetsuite_product] = useState("")
  const [pSchool, setPSchool] = useState("")
  const [pQualification_type, setPQualification_type] = useState("")
  const [importData, setImportData] = useState(false)

  let learning = detail?.learning_method?.map((data) => data.learning_method);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      duplicateId: "",
      name: pageData?.name ? pageData?.name : "",
      programme_name: pageData?.programme_name ? pageData?.programme_name : "",
      pmc_id: pageData?.pmc_id ? pageData?.pmc_id : "",
      status: pageData?.status ? pageData?.status : "",
      publish_status: pageData?.publish_status ? pageData?.publish_status : "",
      Duration: pageData?.Duration ? pageData?.Duration : "",
      campus_location: pageData?.campus_ids ? pageData?.campus_ids : [],
      brandID: pageData?.brand_ids ? pageData?.brand_ids : "",
      categories: pageData?.categoryIds ? pageData?.categoryIds : [],
      banner_image: pageData?.banner_image ? pageData?.banner_image : "",
      dashboard_image: pageData?.dashboard_image ? pageData?.dashboard_image : "",
      netsuite_product: pageData?.netsuite_product ? pageData?.netsuite_product : "",
      School: pageData?.School ? pageData?.School : "",
      qualification_type: pageData?.qualification_type != null && pageData.qualification_type !== "" ? pageData.qualification_type : "",
      Code: pageData?.Code ? pageData?.Code : "",
      saqa_id: pageData?.saqa_id ? pageData?.saqa_id : "",
      SAQA_Name: pageData?.SAQA_Name ? pageData?.SAQA_Name : "",
      NQF_Level: pageData?.NQF_Level ? pageData?.NQF_Level : "",
      Credits: pageData?.Credits ? pageData?.Credits : "",
      Term_Period: pageData?.Term_Period ? pageData?.Term_Period : "",
      SAQA_Link: pageData?.SAQA_Link ? pageData?.SAQA_Link : "",
      Delivery_Methods: pageData?.learning_methods
      ? pageData?.learning_methods : [],
      full_time_online_fee: pageData?.full_time_online_fee ? pageData?.full_time_online_fee : "",
      part_time_online_fee: pageData?.part_time_online_fee ? pageData?.part_time_online_fee : "",
      full_time_hybrid_fee: pageData?.full_time_hybrid_fee ? pageData?.full_time_hybrid_fee : "",
      registration_fee: pageData?.registration_fee ? pageData?.registration_fee : "",
      installments: pageData?.installments ? pageData?.installments : "",
      earlyBirds: ((type === "open" || type === "duplicate") && pageData?.earlyBirds !== "" || pageData?.earlyBirds !== null) ? pageData?.earlyBirds : 2,
      cost_terms: pageData?.cost_terms ? pageData?.cost_terms : "",
      options_factsheet: (pageData?.options_factsheet != null) ? pageData.options_factsheet : 1,
      override_default_tc: pageData?.override_default_tc ? pageData?.override_default_tc : 0,
      terms_conditions_page: pageData?.terms_conditions_page ? pageData?.terms_conditions_page : "",
      netsuite_department_id: pageData?.netsuite_department_id ? pageData?.netsuite_department_id : "",
      toggle_override_info: pageData?.toggle_override_info ? pageData?.toggle_override_info : 0,
      page_type :"0",
      factsheet_pdf : pageData?.factsheet_pdf || "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("Marketing Programme Name is required")
        .max(255, "Marketing Programme Name should not be greater than 255 Character")
        .trim(),
      publish_status: Yup.string().required("Publish status is required"),
      brandID: Yup.string().required("Assigned Brand is required"),
      terms_conditions_page: Yup.string().when("override_default_tc", {
        is: 1,
        then: Yup.string().required("Assigned Terms & Conditions Page is required"),
      }),
      full_time_hybrid_fee: Yup.string().when("Delivery_Methods", {
        is: (methods) => Array.isArray(methods) && methods.includes('full'),
        then: Yup.string().required("Full Time, Hybrid Fee is required"),
        otherwise: Yup.string(),
      }),
      full_time_online_fee: Yup.string().when("Delivery_Methods", {
        is: (methods) => Array.isArray(methods) && methods.includes('fulltimeonline'),
        then: Yup.string().required("Full Time, Online Fee is required"),
        otherwise: Yup.string(),
      }),
      part_time_online_fee: Yup.string().when("Delivery_Methods", {
        is: (methods) => Array.isArray(methods) && methods.includes('parttimeonline'),
        then: Yup.string().required("Part Time, Online Fee is required"),
        otherwise: Yup.string(),
      }), 
      factsheet_pdf: Yup.string().when("options_factsheet", {
        is: 2,
        then: Yup.string().required("Fact Sheet PDF is required"),
        otherwise: Yup.string(),
      }),
           
    // factsheet_pdf : factsheet == 2 ? Yup.mixed().required("Fact Sheet PDF is required") : ""
    }),

    onSubmit: (values, action) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("page_type", values.page_type);
      formData.append("name", values.name);
      formData.append("programme_name", values.programme_name);
      formData.append("pmc_id", values.pmc_id);
      formData.append("status", values.status);
      formData.append("publish_status", values.publish_status);
      formData.append("Duration", values.Duration);
      values.campus_location.forEach((val, idx) => {
        formData.append(`${"campus_location"}[${idx}]`, val);
      });
      values.campus_location.forEach((val, idx) => {
        formData.append(`${"campus_location"}[${idx}]`, val);
      });
      values.brandID.forEach((val, idx) => {
        formData.append(`${"brandID"}[${idx}]`, val);
      });
      values.categories.forEach((val, idx) => {
        formData.append(`${"categories"}[${idx}]`, val);
      });
      values.Delivery_Methods.forEach((val, idx) => {
        formData.append(`${"Delivery_Methods"}[${idx}]`, val);
      });
      formData.append("banner_image", values.banner_image);
      formData.append("dashboard_image", values.dashboard_image);
      formData.append("factsheet_pdf",values.factsheet_pdf)
      formData.append("netsuite_product", values.netsuite_product);
      formData.append("netsuite_department_id", values.netsuite_department_id);
      formData.append("School", values.School);
      formData.append("qualification_type", values.qualification_type);
      formData.append("Code", values.Code);
      formData.append("SAQA_Name", values.SAQA_Name);
      formData.append("saqa_id", values.saqa_id);
      formData.append("SAQA_Link", values.SAQA_Link);
      formData.append("NQF_Level", values.NQF_Level);
      formData.append("Credits", values.Credits);
      formData.append("Term_Period", values.Term_Period);
      formData.append("full_time_online_fee", values.full_time_online_fee);
      formData.append("part_time_online_fee", values.part_time_online_fee);
      formData.append("full_time_hybrid_fee", values.full_time_hybrid_fee);
      formData.append("registration_fee", values.registration_fee);
      formData.append("installments", values.installments);
      formData.append("cost_terms", values.cost_terms);
      formData.append("options_factsheet", values.options_factsheet);
      formData.append(
        "override_default_tc",
        values.override_default_tc == true ? 1 : 0
      );
      formData.append("terms_conditions_page", values.terms_conditions_page);
      formData.append("toggle_override_info", values.toggle_override_info);
      formData.append('earlyBirds', values.earlyBirds == "" || values.earlyBirds == null ? 2 : values.earlyBirds);

      if (type == "create") {
        AddAndUpdateProgrammePageCancelToken(formData)
          .then(() => {
            Swal.fire("Success!", "Programme created successfully!", "success");
            action.resetForm();
            setLoading(false);
            history.push("/websiteManagement/pages/programmes_and_short_courses/programme_pages/table");
          })
          .catch((error) => {
            Swal.fire("Error!", "Failed to create Programme", "error");
            setLoading(false);
          });
      }
      else if (type === "update" || type === "open" || type == "duplicate") {
        if(type == "duplicate"){
          formData.append("duplicateId", id);          
        }else{
          formData.append("id", id);
        }
        AddAndUpdateProgrammePageCancelToken(formData).then(() => {
          Swal.fire(
            "Success!",
            "Programme Updated Successfully!",
            "success"
          );
          action.resetForm();
          setLoading(false);
          setUpdateData(Date.now())
          if(type == "duplicate"){
            history.push("/websiteManagement/pages/programmes_and_short_courses/programme_pages/table");
          }
        })
      }
      action.setSubmitting(false);
    },
  });


  useEffect(()=>{
    if (formik.values.options_factsheet == 0 || formik.values.options_factsheet == 1) {
      formik.setFieldValue("factsheet_pdf", "")
    } else {
      formik.setFieldValue("factsheet_pdf", formik.values.factsheet_pdf)
    }

    if (formik.values.override_default_tc !== 1) {
      formik.setFieldValue("terms_conditions_page", "")
    } else {
      formik.setFieldValue("terms_conditions_page", formik.values.terms_conditions_page);
    }
  },[formik.values])

  useEffect(() => {
    setLearningMethodArr(learningMethodOptionArr);
  }, []);

  useEffect(() => {
    setPMC_id(formik.values.pmc_id)
  }, [formik.values.pmc_id])

  useEffect(()=>{
    if(formik.values.toggle_override_info == 0 || pmc_id == ""){
      setImportData(false)
    }
  }, [formik.values.toggle_override_info, pmc_id])

  const handleDownload = async () => {
    setButtonLoading(true);
    try {
      const success = await GenerateFactSheet({ page_id: id });
  
      let url = IMAGE_URL + (success.data?.fileName).replaceAll("home/myaie/public_html/", "").replaceAll("public", "");
      setButtonLoading(false);
  
      window.open(url, "_blank");
  
    } catch (error) {
      setButtonLoading(false);
      console.error("Error generating fact sheet:", error);
    }
  };
  
  
  
  useEffect(() => {
    const cancelTokenSources = [];
    setDetail([])
    const programmeDetails = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetProgramDetailCancelToken({ PMC_ID: pmc_id }, source.token);
        if (res.status === 200) {
          setProgrammeDetails(res.data[0])
          setProgrammName(res.data[0].name)
          setNetsuite_product(res.data[0].netsuite_product)
          setPSchool(res.data[0].school)
          setPQualification_type(res.data[0].qualification_type)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if (formik.values.pmc_id !== "") {
      programmeDetails()
    }
  }, [pmc_id])


  
  // const handlePDFPreview = () => {
  //   GenerateFactSheet({page_id : id})
  //   .then((success) => {window.location.href("/" + IMAGE_URL + (success.data.fileName).replace("public",""))})
  // }
  // useEffect(() => {
  //   GenerateFactSheet({page_id : id})
  //   .then((success) => setUrl(IMAGE_URL + (success.data.fileName).replace("public","")))
  // },[id])
  


  useEffect(() => {
    const cancelTokenSources = [];
    const cancelDropdownSource1 = [];
    const cancelDropdownSource2 = [];
    const cancelDropdownSource3 = [];
    const cancelDropdownSource4 = [];
    const cancelDropdownSource5 = [];
    const cancelDropdownSource6 = [];
    const cancelDropdownSource7 = [];
    const getSchool = async () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetSchoolList();
        if (res.status == 200) {
          setSchoolList(res.data.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
 
    const getCampusLocationData = async () => {
      cancelDropdownSource1.forEach((source) => {
        source.cancel("New request made");
      });
      const source1 = axios.CancelToken.source();
      cancelDropdownSource1.push(source1);

      try {
        const res = await GetCampusLocationDropdown(source1.token);
        if (res.status == 200) {
          setCampusLocList(res.data.campusLocationFilters);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };


    const getNetsuiteProductData = async () => {
      cancelDropdownSource2.forEach((source) => {
        source.cancel("New request made");
      });
      const source2 = axios.CancelToken.source();
      cancelDropdownSource2.push(source2);

      try {
        const res = await GetNetsuiteProductListCancelToken("", source2.token);
        if (res.status == 200) {
          const respData =
            res.data.data && res.data.data.items ? res.data.data.items : [];
          const modifyResponse = modifiyOptionsForSelect(respData, "netsuite");
          setNetsuiteProdList(modifyResponse);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getBrandData = async () => {
      cancelDropdownSource3.forEach((source) => {
        source.cancel("New request made");
      });
      const source3 = axios.CancelToken.source();
      cancelDropdownSource3.push(source3);

      try {
        const res = await getBrandTemplateList(source3.token);
        if (res.status == 200) {
          setBrandList(res.data?.brandTypesFilters);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getPopupData = async () => {
      cancelDropdownSource4.forEach((source) => {
        source.cancel("New request made");
      });
      const source4 = axios.CancelToken.source();
      cancelDropdownSource4.push(source4);

      try {
        const res = await GetPopUpListStatic(source4.token);
        if (res.status == 200) {
          setPopupList(res.data.Data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getCategoryData = async () => {
      cancelDropdownSource5.forEach((source) => {
        source.cancel("New request made");
      });
      const source5 = axios.CancelToken.source();
      cancelDropdownSource5.push(source5);

      try {
        const res = await GetCategoryListStatic(source5.token);
        if (res.status == 200) {
          setCategoryList(res.data.Data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getAllPmcProgrammes = async () => {
      // cancelDropdownSource7.forEach((source) => {
      //   source.cancel("New request made");
      // });
      // const source5 = axios.CancelToken.source();
      // cancelDropdownSource7.push(source5);

      try {
        const res = await getAllPmcProgrammesDropdown();
        if (res.status == 200) {
          setProgrammeList(res.data.data)
        }
      } catch (error) {
        // if (!axios.isCancel(error)) {
        //   console.error(error);
        // }
      }
    };


    // const getPageFilters = async () => {
    //   cancelDropdownSource6.forEach((source) => {
    //     source.cancel("New request made");
    //   });
    //   const source3 = axios.CancelToken.source();
    //   cancelDropdownSource6.push(source3);

    //   try {
    //     const res = await getPagesListForPmcFilters(source3.token);
    //     if (res.status == 200) {
    //       setPageFilterdata({
    //         BrandList: res.data?.getBrandtemplate,
    //         status: res.data?.getStatus,
    //       });
    //     }
    //   } catch (error) {
    //     if (!axios.isCancel(error)) {
    //       console.error(error);
    //     }
    //   }

      
    // };
    const getPageFilters = async () => {
      try {
        const res = await getPagesFilters();
        setPageFilterdata({
          ...res.data,
          status: res.data.getStatus ? res.data.getStatus : [],
          BrandList: res.data.getBrandtemplate ? res.data.getBrandtemplate : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    getSchool();
    getCampusLocationData();
    getNetsuiteProductData();
    getBrandData();
    getPopupData();
    getCategoryData();
    getPageFilters();
    getAllPmcProgrammes()

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource1.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource2.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource3.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource4.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource5.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [type]);





  useEffect(() => {
    const cancelTokenSources = [];

    const getAssignPageListData = async () => {
      setAssignPageLoading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const payload = {
          page: assignPageTableState.page,
          perPage: assignPageTableState.perPage,
          search: assignPageSearch,
          key: assignPageTableState.sortKey,
          sort: assignPageTableState.sortOrder,
          exportStatus: false,
          setSearch: setAssignPageSearch,
          viaBrand: pageBrand.arr,
          viaStatus: pageStatus.arr,
        };
        const res = await getPagesListWithToken(payload, source.token);
        if (res.status == 200) {
          setAssignPageTotalRows(res.data?.Data?.total);
          setAssignPageList(res.data?.Data?.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      } finally {
        setAssignPageLoading(false);
      }
    };

    getAssignPageListData();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [assignPageTableState, assignPageSearch, pageStatus, pageBrand]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pmcpedit"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const assignPage = assignPageList.filter((page) => {
      return page.id === formik.values.terms_conditions_page;
    });
    setAssignedPage(assignPage);
  }, [assignPageList]);

  useEffect(() => {
    const data = netsuiteProdList.filter((item) => item.value == formik.values?.netsuite_product);
    const netsuit_dId = data[0]?.departmentId;

    if (netsuit_dId) {
      formik.setFieldValue("netsuite_department_id", netsuit_dId);
    }
  }, [netsuiteProdList, formik.values?.netsuite_product]);

  const handleChangeInstallments = (value) => {
    const prevValue = formik.values.installments
      .split(",")
      .filter((item) => item != "");
    if (prevValue.includes(value)) {
      const ind = prevValue.findIndex((item) => item == value);
      prevValue.splice(ind, 1);
    } else {
      prevValue.push(value);
    }
    formik.setFieldValue("installments", prevValue.join(","));
  };

  const handlereset = async () => {
    history.push(`/websiteManagement/pages/programmes_and_short_courses/programme_pages/table`);
    formik.resetForm();
  };

  const assignPageColumn = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortField: "PageTitle",
      // wrap: true,
      cell: (row) => (
        <Link to={`/website_management/pages/staticpages/table/update/${row?.PageID}`}  onClick={() => {
            $("#assignPagePopup").modal("hide");
          }}>
        <span
          className="as-text-blue curser feature-name"
          title={row.title}
        >
          <span className="textLimit100">
            {row.PageTitle ? row.PageTitle : "-"}
          </span>
        </span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "Status",
      sortField: "status",
      sortable: true,
      cell: (row) => (row.status ? RenderPMCStatusCol(row.status).html : "-"),
    },
    {
      name: "Brand Templates",
      selector: "Brand Templates",
      sortable: false,
      cell: (row) => (
        <div className="d-flex flex-wrap">
          {row?.brandTemplete?.length > 0
            ? row?.brandTemplete.map(
              (brand, index) =>
                brand?.templateName && (
                  <div className="brandList-pmc" title={brand?.templateName}>
                    {brand?.templateName}
                  </div>
                )
            )
            : "-"}
        </div>
      ),
    },
    {
      name: "Action",
      selector: "",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {formik.values.terms_conditions_page != row?.PageID ? (
              <button
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => {
                  formik.setFieldValue("terms_conditions_page", row?.PageID);
                  setAssignedPage({ ...row });
                  formik.setFieldValue(
                    "terms_conditions_page_type",
                    row?.PageTitle
                  );
                  $("#assignPagePopup").modal("hide");
                }}
                title="Assign Page"
              >
                <i className="fal fa-plus"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger rounded-circle"
                onClick={() => {
                  formik.setFieldValue("terms_conditions_page", "");
                  formik.setFieldValue("terms_conditions_page_type", "");
                  setAssignedPage({});
                }}
                title="Assign Page"
              >
                <i className="fal fa-minus"></i>
              </button>
            )}
          </div>
        </div>
      ),
    },
  ]);


  useEffect(() => {
    const payload = {
      // page:assignPageTableState.page,
      // perPage:assignPageTableState.perPage,
      // search:assignPageSearch,
      // key:assignPageTableState.sortKey,
      // sort:assignPageTableState.sortOrder,
      exportStatus: "true",
      // setSearch:setAssignPageSearch,
      // viaBrand:pageBrand.arr,
      // viaStatus:pageStatus.arr,
    };

    getPagesList(payload)
      .then((res) => {
        if (res.status == 200) {
          setAllPageListData(res.data?.Data);
        }
      })
      .catch((err) => console.log(err));
  }, []);



  const handleNext = () => {
    if (!formik.errors.name && formik.values.name) {
      setNext(true);
    } else {
      formik.setTouched({ ...formik.touched, name: true });
    }
  };


  const exportAssignPageData = (fileType, fileName) => {
    let exportData = [...assignedArticulationOptionList];
    const header = ["Name", "Status", "brand templates"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const payload = {
      page: assignPageTableState.page,
      perPage: assignPageTableState.perPage,
      search: assignPageSearch,
      key: assignPageTableState.sortKey,
      sort: assignPageTableState.sortOrder,
      exportStatus: true,
      viaBrand: pageBrand.arr,
      viaStatus: pageStatus.arr,
    };
    GetPagesList(payload)
      .then((res) => {
        exportData = res.data.Data;
        exportData = exportData?.map((row) => ({
          ...row,
          Name: row?.PageTitle ? row?.PageTitle : "-",
          Status: row?.status ? row.status : "-",
          "brand templates":
            row?.brandTemplete?.length > 0
              ? row.brandTemplete.map((item) => item?.templateName).join(", ")
              : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, exportData });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = exportData.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = exportData.map((row) => {
            return [row["Name"], row["Status"], row["brand templates"]];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(()=>{
    if(formik.values.toggle_override_info == 0){
      setImportData(false)
    }
  },[formik.values.toggle_override_info])

  useEffect(() => {
    setFactsheet(formik.values.options_factsheet)
  },[formik.values.options_factsheet])

  useEffect(() => {
    
    if (formik.values.toggle_override_info == 0) {
      setImportData(false)
      formik.setFieldValue("School", "");
      formik.setFieldValue("qualification_type", "");
      formik.setFieldValue("Delivery_Methods", []);
      formik.setFieldValue("netsuite_product", "");
      formik.setFieldValue("netsuite_department_id", "");
      formik.setFieldValue("installments", "");
      formik.setFieldValue("programme_name", "");
      formik.setFieldValue("SAQA_Name", "");
      formik.setFieldValue("saqa_id", "");
      formik.setFieldValue("SAQA_Link", "");
      formik.setFieldValue("NQF_Level", "");
      formik.setFieldValue("Duration", "");
      formik.setFieldValue("Code", "");
      formik.setFieldValue("credit_status",0);
      formik.setFieldValue("Credits", "");
      formik.setFieldValue("Term_Period", "");
      formik.setFieldValue("full_time_hybrid_fee", 0);
      formik.setFieldValue("full_time_online_fee", 0);
      formik.setFieldValue("part_time_online_fee", 0);
      formik.setFieldValue("registration_fee", 0);
      formik.setFieldValue("cost_terms", "");
      formik.setFieldValue("status", "");
      // formik.setFieldValue("course_delivery", []);
      formik.setFieldValue("earlyBirds", 2);
    }
  }, [formik.values.toggle_override_info])
  useEffect(() => {
    setFactsheet(formik.values.options_factsheet)
  },[formik.values.options_factsheet])



  const handlePMCData = () => {
      setImportData(true)
      // setDisabled(true)
      let deliveryMethodData = programmeDetails?.getLearningMethods?.map((el) => el?.learning_method)
      // let courseDeliveryData = programmeDetails?.course_delivery ? programmeDetails.course_delivery?.map((item) => item?.delivery_method)
      //   : []
      formik.setFieldValue("School", programmeDetails?.School);
      formik.setFieldValue("Delivery_Methods", deliveryMethodData ? deliveryMethodData : []);
      formik.setFieldValue("qualification_type", programmeDetails?.qualification_type || "");
      formik.setFieldValue("netsuite_product", programmeDetails?.netsuite_product || "");
      formik.setFieldValue("installments", programmeDetails?.installments || "");
      formik.setFieldValue("programme_name", programmeDetails?.name);
      formik.setFieldValue("SAQA_Name", programmeDetails?.SAQA_Name || "");
      formik.setFieldValue("saqa_id", programmeDetails?.saqa_id || "");
      formik.setFieldValue("SAQA_Link", programmeDetails?.SAQA_Link || "");
      formik.setFieldValue("NQF_Level", programmeDetails?.NQF_Level || "");
      formik.setFieldValue("Duration", programmeDetails?.Duration || "");
      formik.setFieldValue("Code", programmeDetails?.Code || "");
      // formik.setFieldValue("credit_status", programmeDetails?.credit_status || 0);
      formik.setFieldValue("Credits", programmeDetails?.Credits || "");
      formik.setFieldValue("Term_Period", programmeDetails?.Term_Period || "");
      formik.setFieldValue("full_time_hybrid_fee", programmeDetails?.full_time_hybrid_fee || 0);
      formik.setFieldValue("full_time_online_fee", programmeDetails?.full_time_online_fee || 0);
      formik.setFieldValue("part_time_online_fee", programmeDetails?.part_time_online_fee || 0);
      formik.setFieldValue("registration_fee", programmeDetails?.registration_fee || 0);
      formik.setFieldValue("cost_terms", programmeDetails?.cost_terms || "");
      formik.setFieldValue("status", programmeDetails?.status || "");
      // formik.setFieldValue("course_delivery", courseDeliveryData || []);
      formik.setFieldValue("earlyBirds", programmeDetails.earlyBirds === "" || programmeDetails.earlyBirds == null ? 2  : programmeDetails.earlyBirds);
      setLoading(false)
  }

  return (
    <>

      <div className="card card-profile-info-card filter-table-bg">
        {!next && type == "create" && <>
          <div className="row">
            <div className="col-md-12">
              <div className="edit-icon new-card-header">
                <h4 className="card-header" title="Programme Page Details">Programme Page Details</h4>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <label htmlFor="site_Name" title={helpText.marketing}>Marketing Programme Name *</label>
              <div className="form-icon-group mb-4" title="site_Name">
                <input
                  type="text"
                  className={`form-control ${formik.touched.name && formik.errors.name && "is-invalid"
                    }`}
                  // name="name"
                  id="name"
                  title={helpText.marketing}  
                  placeholder="Marketing Programme Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-3" title={helpText.linkedPMC}>
                <label htmlFor="school">Linked PMC Programme  <i className="fal fa-info-circle grade-icon"></i></label>
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.pmc_id && formik.touched.pmc_id
                      ? " is-invalid"
                      : "")
                  }
                  name="pmc_id"
                  value={programmeList.filter(
                    (item) => item.value == formik.values.pmc_id
                  )
                  }
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("pmc_id", value.value);
                      setPMC_id(value.value)
                    } else {
                      formik.setFieldValue("pmc_id", "");
                      setPMC_id(value.value)
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={programmeList}
                  maxMenuHeight={175}
                  placeholder={"Select Linked PMC Programme"}
                />
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <label htmlFor="Status" title={helpText.status}>Status <i className="fal fa-info-circle"></i></label>
              <div className="form-icon-group mb-4" title="The status determines the visibility of the programme. When set to draft the page will be viewable on the development websites.">
                <Select
                  className={`form-control custom-select-box ${formik.touched.publish_status && formik.errors.publish_status && "is-invalid"
                    }`}
                  id="publish_status"
                  name="publish_status"
                  // value={statusArr.find((item) => item.value === formik.values.status)}
                  onChange={(option) => formik.setFieldValue("publish_status", option.value)}
                  onBlur={formik.handleBlur}
                  options={statusArr}
                  maxMenuHeight={175}
                  placeholder="Select Status"
                />
              </div>
            </div>
          </div>

          <div className="form-group form-group-save-cancel">
            <button className="btn btn-save btn-success" type="submit" title="Next" disabled={loading} onClick={handleNext}>
              Next
              {loading ? <i className="fas fa-cog fa-spin ml-2"></i> : <i className="fal fa-arrow-right ml-2"></i>}
            </button>


            <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={handlereset}>
              <i className="fal fa-times"></i>
              Cancel
            </button>
            {Object.keys(formik.values).map((key) => {
              if (formik.touched[key] && formik.errors[key]) {
                return (
                  <div className="invalid-feedback d-block" key={key}>
                    {formik.errors[key]}
                  </div>
                );
              }
            })}
          </div>
        </>}

        {((next && type == "create") || type == "open" || type == "duplicate") &&
          <div className="new-card-header">
            <div className="card-header pt-0">Programme Details {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>

            <div className="card-body-inr card-body-info">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3" title={helpText.marketing}>
                      <label htmlFor="name">Marketing Programme Name *</label>
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors["name"] && formik.touched["name"]
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Enter Marketing Programme Name"
                        name="name"
                        id="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-3" title="Linked PMC Programme">
              <label htmlFor="Linked PMC Programme">Linked PMC Programme  <i className="fal fa-info-circle grade-icon"></i></label>
              <Select
              className={
              "form-control custom-select-box " +
              (formik.errors.pmc_id && formik.touched.pmc_id
              ? " is-invalid"
              : "")
              }
              name="Linked PMC Programme"
              value={programmeList.filter(
              (item) => item.value == formik.values.pmc_id
              )}
              onChange={(value) => {
                if (value) {
              formik.setFieldValue("pmc_id", value.value);
              } else {
              formik.setFieldValue("pmc_id", "");
              }
              }}
              onBlur={formik.handleBlur}
              options={programmeList}
              maxMenuHeight={175}
              placeholder={"Select Linked PMC Programme"}
              />
              </div>
              </div> */}

                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3" title={helpText2.linkedPMC}>
                      <label htmlFor="Linked PMC Programme">Linked PMC Programme  <i className="fal fa-info-circle grade-icon"></i></label>
                      <Select
                        // styles={colourStyles}
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.pmc_id &&
                            formik.touched.pmc_id
                            ? " is-invalid"
                            : "")
                        }
                        name="Linked PMC Programme"
                        value={programmeList?.filter(
                          (item) => item.value == formik.values?.pmc_id
                        )}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("pmc_id", value.value);
                            // setImportData(false)
                          } else {
                            formik.setFieldValue("pmc_id", "");
                            formik.setFieldValue("toggle_override_info",0)
                            setPMC_id("")
                          }
                        }}
                        // menuIsOpen={true}
                        onBlur={formik.handleBlur}
                        options={programmeList}
                        maxMenuHeight={175}
                        placeholder={"Select Linked PMC Programme "}
                        isClearable
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3" title={helpText2.status}>
                      <label>
                        Publish Status *{" "}
                        <i className="fal fa-info-circle grade-icon"></i>
                      </label>
                      <Select
                        // styles={colourStyles}
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.publish_status &&
                            formik.touched.publish_status
                            ? " is-invalid"
                            : "")
                        }
                        name="publish_status"
                        value={PublishstatusArr.filter(
                          (val) => val?.value === formik.values.publish_status
                        )}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("publish_status", value.value);
                          } else {
                            formik.setFieldValue("publish_status", "");
                          }
                        }}
                        // menuIsOpen={true}
                        onBlur={formik.handleBlur}
                        options={PublishstatusArr}
                        maxMenuHeight={175}
                        placeholder={
                          formik.values.publish_status
                            ? formik.values.publish_status
                            : "Select Publish Status  "
                        }
                        isClearable
                      />
                    </div>
                  </div>


                  {/* <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                    <label htmlFor="Duration">Duration  <i className="fal fa-info-circle grade-icon"></i></label>
                    <input
                    type="number"
                    className={
                    "form-control" +
                    (formik.errors.Duration && formik.touched.Duration
                    ? " is-invalid"
                    : "")
                    }
                    placeholder="Enter Duration"
                    name="Duration"
                    id="Duration"
                    title="Duration"
                    value={formik.values.Duration}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                    </div>
                    </div> */}

                  <div className="col-md-6 col-lg-4" title="Campus Locations">
                    <label>Campus Locations </label>
                    <div className="form-icon-group mb-4">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.campus_location &&
                            formik.touched.campus_location
                            ? " is-invalid"
                            : "")
                        }
                        name="campus_location"
                        value={campusLocList.filter((val) =>
                          formik.values?.campus_location?.includes(val.value)
                        )}
                        onChange={(value) => {
                          formik.setFieldValue(
                            "campus_location",
                            value ? value.map((v) => v.value) : []
                          );
                        }}
                        onBlur={formik.handleBlur}
                        options={campusLocList}
                        maxMenuHeight={175}
                        placeholder={"Select Campus Location"}
                        isMulti
                      />
                    </div>
                  </div>

                  <div
                    className="col-md-6 col-lg-4"
                    title={helpText2.assignedBrand}
                  >
                    <label>
                      Assigned Brand Templates *{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="form-icon-group mb-4">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.brandID && formik.touched.brandID
                            ? " is-invalid"
                            : "")
                        }
                        name="brandID"
                        value={brandList.filter((val) =>
                          formik.values?.brandID?.includes(val.value)
                        )}
                        onChange={(value) => {
                          formik.setFieldValue(
                            "brandID",
                            value ? value.map((v) => v.value) : []
                          );
                        }}
                        onBlur={formik.handleBlur}
                        options={brandList}
                        maxMenuHeight={175}
                        placeholder={"Select Assigned Brands"}
                        isClearable
                        isMulti
                      />
                    </div>
                  </div>

                  <FileUploadField name="dashboard_image" title={"The image show on the qualification card and on the qualification banner. The optimal resolution is 1440px x 510px"} label="Dashboard Image" formik={formik} accept=".png,.jpg,.jpeg,.svg" col_md={6} col_lg={2} flex_view={true} />
                  <FileUploadField name="banner_image" formik={formik} accept=".png,.jpg,.jpeg,.svg" col_md={6} col_lg={2} label="Banner Image"/>


                  {/* <div className="col-md-6 col-lg-3">
                          <div className="form-group-blk mb-3" title="Banner Image">
                          <label>
                          Banner Image
                          <i className="fal fa-info-circle grade-icon"></i>
                          </label>
                          <div className="form-group atttach-file m-0">
                          <label
                          className={
                          formik.errors.banner_image &&
                          formik.touched.banner_image
                          ? "file-req is-invalid"
                          : ""
                          }
                          >
                          <i className="fal fa-upload"></i>
                          <span>Upload</span>
                          <input
                          type="file"
                          title="Upload"
                          accept="image/*"
                          className="form-control form-control-aatch-file"
                          onChange={(e) => {
                            if (e?.target?.files?.length) {
                          formik.setFieldValue("banner_image", e?.target?.files[0]);
                          formik.setFieldValue(
                          "banner_image",
                          e?.target?.files[0]
                          );
                          }
                          }}
                          />
                          </label>
                          </div>
                          {formik.values.banner_image &&
                          formik.values.banner_image && (
                          <>
                          <div className="frm-group">
                          <ul className="list-unstyled attached-file-ul m-0">
                          <li>
                          <a
                          title={formik.values.banner_image}
                          href={
                          formik.values.banner_image?.includes(
                          "blob"
                          )
                          ? formik.values.banner_image
                          : `${downloadURL}/${IMAGE_URL}/${formik.values.banner_image}`
                          .replaceAll("/public/", "")
                          .replaceAll(
                          "home/myaie/public_html/",
                          ""
                          )
                          }
                          target="_blank"
                          >
                          <i className="fal fa-file"></i>
                          &nbsp;
                          {formik.values.banner_image?.name
                          ? TrimText(
                          formik.values.banner_image?.name,
                          40
                          )
                          : TrimText(
                          formik.values.banner_image
                          .split("/")
                          .reverse()[0],
                          40
                          )}
                          </a>
                          <button
                          className="btn btn-danger rounded-circle btn-dropdown-item"
                          title="Delete"
                          onClick={() => {
                            formik.setFieldValue("banner_image", "");
                          }}
                          >
                          <i className="fal fa-trash-alt"></i>
                          </button>
                          </li>
                          </ul>
                          </div>
                          </>
                          )}
                          </div>
                          </div> */}
                </div>
                <hr />

                {formik.values.pmc_id !== "" &&
                  <>
                    <div className="row mb-3">
                      <div className="col-12 d-flex align-items-center">
                        {/* <div className="d-flex align-items-center"> */}
                        <div
                          className="d-flex mr-3"
                          title="Override PMC Data on Website.This does not change data on the linked PMC Programme"
                        >
                          <label className="mb-0">
                            Override Information <i className="fal fa-info-circle grade-icon"></i>
                          </label>
                          <div className="toggle-switch ml-2" title="Override PMC Data on Website.This does not change data on the linked PMC Programme">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={formik.values.toggle_override_info == "1"}
                                onChange={(e) => {
                                  if (formik.values.toggle_override_info == "1") {
                                    formik.setFieldValue(
                                      "toggle_override_info", "0"
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      "toggle_override_info", "1"
                                    );
                                  }
                                }}
                              />
                              <span className="slider slider-round"></span>
                            </label>
                          </div>
                        </div>
                        {/* </div> */}
                        {formik.values.toggle_override_info == 1 &&
                          <button
                            className="btn btn-save btn-success"
                            type="button"
                            title="Import PMC Programme"
                            onClick={()=> handlePMCData()}
                            >
                            {disabled && (
                              <i className="fas fa-cog fa-spin"></i>
                            )}
                            Import PMC Programme Data
                          </button>}
                      </div>
                    </div>
                    <hr />
                  </>}

                {(!(formik?.values?.pmc_id) || formik?.values?.toggle_override_info == 1) && (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="name">Programme Name</label>
                          <div className="d-flex flex-column">
                            <input
                              type="text"
                              className={
                                "form-control" +
                                (formik.errors["programme_name"] && formik.touched["programme_name"]
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Programme Name"
                              name="programme_name"
                              id="programme_name"
                              title="Programme Name"
                              value={formik.values["programme_name"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {importData && formik.values.programme_name !== programmeDetails?.name && (
                              <div className="mt-1">
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("programme_name", programmeDetails.name);
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="Netsuite Product">
                          <label>
                            Netsuite Product {" "}
                            <i className="fal fa-info-circle grade-icon"></i>
                          </label>
                          <div className="d-flex flex-column">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (formik.errors.netsuite_product &&
                                  formik.touched.netsuite_product
                                  ? " is-invalid"
                                  : "")
                              }
                              name="netsuite_product"
                              value={netsuiteProdList.filter(
                                (val) => val?.value == formik.values.netsuite_product
                              )}
                              onChange={(value) => {
                                if (value) {

                                  formik.setFieldValue("netsuite_product", value.value);
                                  formik.setFieldValue("netsuite_department_id", value.departmentId);
                                } else {
                                  formik.setFieldValue("netsuite_product", "");
                                  formik.setFieldValue("netsuite_department_id", "");
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={netsuiteProdList}
                              maxMenuHeight={175}
                              placeholder={"Select Netsuite Product"}
                              isClearable
                            />
                            {importData && (programmeDetails?.netsuite_product !== null ? programmeDetails?.netsuite_product : "") !== formik.values.netsuite_product && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("netsuite_product", programmeDetails.netsuite_product);
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="School">
                          <label htmlFor="school">School </label>
                          <div className="d-flex flex-column">
                            <Select
                              className={
                                "form-control custom-select-box " +
                                (formik.errors.School && formik.touched.School
                                  ? " is-invalid"
                                  : "")
                              }
                              name="School"
                              value={schoolList.filter(
                                (item) => item.value == formik.values.School
                              )}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("School", value.value);
                                } else {
                                  formik.setFieldValue("School", "");
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={schoolList}
                              maxMenuHeight={175}
                              placeholder={"Select School"}
                              isClearable
                            />
                            {importData && programmeDetails?.School !== formik.values.School && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("School", programmeDetails.School);
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div
                          className="form-group-blk mb-3"
                          title="Qualification Type"
                        >
                          <label htmlFor="qualification_type">
                            Qualification Type
                          </label>
                          <div className="d-flex flex-column">
                            <Select
                              className={
                                "form-control custom-select-box " +
                                (formik.errors.qualification_type && formik.touched.qualification_type
                                  ? " is-invalid"
                                  : "")
                              }
                              name="qualification_type"
                              value={dropDownValues?.programs?.find(item => item.value === formik.values.qualification_type) || ""} // Correctly find the selected option
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("qualification_type", value.value);
                                } else {
                                  formik.setFieldValue("qualification_type", "");
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={dropDownValues?.programs}
                              maxMenuHeight={175}
                              isClearable
                              placeholder={"Select Qualification"}
                            />

                            {importData && (programmeDetails?.qualification_type == null ? "" : programmeDetails?.qualification_type) !== formik.values.qualification_type && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("qualification_type", programmeDetails.qualification_type || "");
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="code">Code 
                          {/* {importData && <i className="fal fa-ban cat-ban ml-2"></i>} */}
                          </label>
                          <input
                            type="text"
                            className={
                              "block-input form-control" +
                              (formik.errors.Code && formik.touched.Code
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter code"
                            name="Code"
                            id="Code"
                            title="Code"
                            value={formik.values.Code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={importData}
                          />
                        </div>
                      </div>
                      
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="name">SAQA ID </label>
                          <div className="d-flex flex-column">
                            <input
                              type="text"
                              className={
                                "form-control" +
                                (formik.errors["saqa_id"] &&
                                  formik.touched["saqa_id"]
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter SAQA ID"
                              name="saqa_id"
                              id="saqa_id"
                              title="SAQA ID"
                              value={formik.values["saqa_id"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {importData && (programmeDetails?.saqa_id == null ? "" : programmeDetails?.saqa_id) !== formik.values.saqa_id && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("saqa_id", programmeDetails.saqa_id || "");
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="name">SAQA Registered Name </label>
                          <div className="d-flex flex-column">
                            <input
                              type="text"
                              className={
                                "form-control" +
                                (formik.errors["SAQA_Name"] &&
                                  formik.touched["SAQA_Name"]
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter SAQA Name"
                              name="SAQA_Name"
                              id="SAQA_Name"
                              title="SAQA Registered Name"
                              value={formik.values["SAQA_Name"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {importData && (programmeDetails?.SAQA_Name == null ? "" : programmeDetails?.SAQA_Name) !== formik.values.SAQA_Name && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("SAQA_Name", programmeDetails.SAQA_Name || "");
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="SAQA_Link">
                            SAQA Link{" "}
                            <i className="fal fa-info-circle grade-icon"></i>
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              type="text"
                              className={
                                "form-control" +
                                (formik.errors["SAQA_Link"] &&
                                  formik.touched["SAQA_Link"]
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter SAQA Link"
                              name="SAQA_Link"
                              id="SAQA_Link"
                              title="SAQA Link"
                              value={formik.values["SAQA_Link"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {importData && (programmeDetails?.SAQA_Link == null ? "" : programmeDetails?.SAQA_Link) !== formik.values.SAQA_Link && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("SAQA_Link", programmeDetails.SAQA_Link || "");
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="The programme duration in text e.g. 1 day, 4 weeks, 1 year, 2-4 years">
                          <label htmlFor="Duration">Duration  <i className="fal fa-info-circle grade-icon"></i></label>
                          <input
                            type="number"
                            className={
                              "mb-1 form-control" +
                              (formik.errors.Duration && formik.touched.Duration
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter Duration"
                            name="Duration"
                            id="Duration"
                            // title="Duration"
                            value={formik.values.Duration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {importData && programmeDetails?.Duration && programmeDetails?.Duration !== formik.values.Duration && (
                            <>
                            <div>
                              <span className="reset-button" onClick={() => {
                                formik.setFieldValue("Duration", programmeDetails.Duration);
                              }}>
                                <i className="fal fa-undo"></i> Reset Changes
                              </span>
                            </div>
                                </>
                          )}
                        </div>
                      </div>


                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="NQF Level">
                          <label htmlFor="NQF_Level">NQF Level </label>
                          <div className="d-flex flex-column">
                            <Select
                              className={
                                "mb-1 form-control custom-select-box" +
                                (formik.errors.NQF_Level && formik.touched.NQF_Level
                                  ? " is-invalid"
                                  : "")
                              }
                              name="NQF_Level"
                              value={NQF_LevelArr.filter(
                                (val) => val?.value === formik.values.NQF_Level
                              )}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("NQF_Level", value.value);
                                } else {
                                  formik.setFieldValue("NQF_Level", "");
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={NQF_LevelArr}
                              maxMenuHeight={175}
                              placeholder={
                                formik.values.NQF_Level
                                  ? formik.values.NQF_Level
                                  : "Select NQF Level  "
                              }
                              isClearable
                            />
                            {importData && programmeDetails?.NQF_Level && programmeDetails?.NQF_Level !== formik.values.NQF_Level && (
                              <div>
                                <span className="reset-button" onClick={() => formik.setFieldValue("NQF_Level", programmeDetails.NQF_Level)}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="credit">
                            Credits 
                            {/* importData && <i className="fal fa-ban cat-ban ml-2"></i>} */}
                          </label>
                          <input
                            type="number"
                            className={
                              "block-input form-control" +
                              (formik.errors.Credits && formik.touched.Credits
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter Credits"
                            name="Credits"
                            id="Credits"
                            title="Credits"
                            disabled={importData}
                            value={formik.values.Credits}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="Term Periods">
                          <label htmlFor="Term_Period">Term Periods 
                          {/* {importData && <i className="fal fa-ban cat-ban ml-2"></i>} */}
                          </label>
                          <Select
                            className={`form-control custom-select-box ${formik.errors.Term_Period && formik.touched.Term_Period ? 'is-invalid' : ''} ${importData ? 'disabled-select' : ''}`}
                            name="Term_Period"
                            value={TermPeriods?.filter(
                              (val) => val.value === formik.values.Term_Period
                            )}
                            onChange={(value) => {
                              if (value) {
                                formik.setFieldValue("Term_Period", value.value);
                              } else {
                                formik.setFieldValue("Term_Period", "");
                              }
                            }}
                            isDisabled={importData}
                            onBlur={formik.handleBlur}
                            id="Term_Period"
                            options={TermPeriods}
                            maxMenuHeight={175}
                            placeholder={
                              formik.values.Term_Period
                                ? formik.values.Term_Period
                                : "Select Term Period  "
                            }
                            isClearable
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-6" title="Learning Methods">
                        <label>Learning Methods </label>
                        <div className="form-icon-group mb-1">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (formik.errors.Delivery_Methods &&
                                formik.touched.Delivery_Methods
                                ? " is-invalid"
                                : "")
                            }
                            name="Delivery_Methods"
                            value={dropDownValues?.study_type?.filter((data) =>
                              formik.values.Delivery_Methods?.includes(data.value)
                            )}
                            onChange={(value) =>
                              formik.setFieldValue(
                                "Delivery_Methods",
                                value ? value.map((v) => v.value) : []
                              )
                            }
                            onBlur={formik.handleBlur}
                            options={dropDownValues?.study_type}
                            maxMenuHeight={175}
                            placeholder={"Select Learning Method"}
                            isClearable
                            isMulti
                          />
                        </div>
                          {importData && programmeDetails && 
                          programmeDetails?.getLearningMethods &&
                          JSON.stringify(
                            formik.values.Delivery_Methods?.sort()
                          ) !== JSON.stringify(
                            programmeDetails.getLearningMethods.map(el => el.learning_method).sort() 
                          ) && (
                            <div>
                              <span className="reset-button" onClick={() => {
                                formik.setFieldValue("Delivery_Methods", programmeDetails.getLearningMethods.map(el => el.learning_method));
                              }}>
                                <i className="fal fa-undo"></i> Reset Changes
                              </span>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 flex">
                      <div className="card-header pt-0 d-flex align-items-center">
                          <i
                            className="fal fa-usd-circle mr-2"
                            style={{ color: "var(--topbar-color)" }}
                          ></i> Pricing
                          {importData && (
                            [
                              { key: 'full_time_hybrid_fee', defaultValue: 0 },
                              { key: 'full_time_online_fee', defaultValue: 0 },
                              { key: 'part_time_online_fee', defaultValue: 0 },
                              { key: 'registration_fee', defaultValue: 0 },
                              { key: 'cost_terms', defaultValue: '' },
                              { key: 'installments', defaultValue: '' },
                              { key: 'earlyBirds', defaultValue: 2 }
                            ].some(({ key, defaultValue }) => {
                              const programmeValue = programmeDetails?.[key] ?? defaultValue;
                              const formikValue = formik.values[key];

                              if (key === 'installments') {
                                const normalize = str =>
                                  str
                                    .split(',')
                                    .map(val => val.trim())
                                    .sort()
                                    .join(',');
                                return normalize(programmeValue) !== normalize(formikValue);
                              } else {
                                return String(programmeValue) !== String(formikValue);
                              }
                            }) && (
                              <div>
                                <span
                                  className="reset-button"
                                  onClick={() => {
                                    [
                                      { key: 'full_time_hybrid_fee', defaultValue: 0 },
                                      { key: 'full_time_online_fee', defaultValue: 0 },
                                      { key: 'part_time_online_fee', defaultValue: 0 },
                                      { key: 'registration_fee', defaultValue: 0 },
                                      { key: 'cost_terms', defaultValue: '' },
                                      { key: 'installments', defaultValue: '' },
                                      { key: 'earlyBirds', defaultValue: 2 }
                                    ].forEach(({ key, defaultValue }) => {
                                      formik.setFieldValue(key, programmeDetails?.[key] ?? defaultValue);
                                    });
                                  }}
                                >
                                  <i className="fal fa-undo ml-2"></i> Reset Changes
                                </span>
                              </div>
                            )
                          )}

                        </div>

                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="full_time_hybrid_fee" >
                            Full Time, Hybrid Fee {formik.values.Delivery_Methods.includes("full") && "*"}
                          </label>
                          <div>
                            <span className="pricing-before-text">R</span>
                            <input
                              type="number"
                              className={
                                "input-price form-control" +
                                (formik.errors.full_time_hybrid_fee &&
                                  formik.touched.full_time_hybrid_fee
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Full Time, Hybrid Fee"
                              name="full_time_hybrid_fee"
                              id="full_time_hybrid_fee"
                              title="Full Time, Hybrid Fee"
                              value={formik.values.full_time_hybrid_fee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="full_time_online_fee">
                            Full Time, Online Fee {formik.values.Delivery_Methods.includes("fulltimeonline") && "*"}
                          </label>
                          <div>
                            <span className="pricing-before-text">R</span>
                            <input
                              type="number"
                              className={
                                "input-price form-control" +
                                (formik.errors.full_time_online_fee &&
                                  formik.touched.full_time_online_fee
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Full Time, Online Fee"
                              name="full_time_online_fee"
                              id="full_time_online_fee"
                              title="Full Time, Online Fee"
                              value={formik.values.full_time_online_fee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="part_time_online_fee">
                            Part Time, Online Fee {formik.values.Delivery_Methods.includes("parttimeonline") && "*"}
                          </label>
                          <div>
                            <span className="pricing-before-text">R</span>
                            <input
                              type="number"
                              className={
                                "input-price form-control" +
                                (formik.errors.part_time_online_fee &&
                                  formik.touched.part_time_online_fee
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Part Time, Online Fee"
                              name="part_time_online_fee"
                              id="part_time_online_fee"
                              title="Part Time, Online Fee"
                              value={formik.values.part_time_online_fee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="registration_fee">Minimum Deposit </label>
                          <div>
                            <span className="pricing-before-text">R</span>
                            <input
                              type="number"
                              className={
                                "input-price form-control" +
                                (formik.errors.registration_fee &&
                                  formik.touched.registration_fee
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Minimum Deposit"
                              name="registration_fee"
                              id="registration_fee"
                              title="Minimum Deposit"
                              value={formik.values.registration_fee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="cost_terms">Cost Terms</label>
                          <input
                            className={
                              "form-control" +
                              (formik.errors.cost_terms && formik.touched.cost_terms
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter Cost Terms"
                            name="cost_terms"
                            id="cost_terms"
                            title="Cost Terms"
                            value={formik.values.cost_terms}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row">
                          <div className="col-auto">
                            <div className="form-group-blk mb-3">
                              <label htmlFor="installments">Instalments</label>
                              <div className="d-flex">
                                {["2M", "3M", "6M", "10M", "12M", "18M", "24M"].map(
                                  (option) => (
                                    <div
                                      className="custom-control custom-checkbox text-left ml-2"
                                      key={option}
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={option}
                                        name={option}
                                        checked={formik.values?.installments?.split(",")?.includes(option)}
                                        onChange={() => handleChangeInstallments(option)}
                                      />
                                      <label
                                        className="custom-control-label label-instalments"
                                        htmlFor={option}
                                      >
                                        {option}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4">
                            <div className="form-group-blk mb-3">
                              <label htmlFor="early_bird_discount">
                                Early Bird Discount
                              </label>
                              <div className="d-flex">
                                <div className="custom-control custom-checkbox text-left">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="5_percent"
                                    name="5_percent"
                                    checked={formik.values.earlyBirds == 1}
                                    onChange={() => {
                                      if (formik.values.earlyBirds == 1) {
                                        formik.setFieldValue("earlyBirds", "2");
                                      } else {
                                        formik.setFieldValue("earlyBirds", "1");
                                      }
                                    }}                                  />
                                  <label
                                    className="custom-control-label label-instalments"
                                    htmlFor="5_percent"
                                  >
                                    5%
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox text-left">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="10_percent"
                                    name="10_percent"
                                    checked={formik.values.earlyBirds == 0}
                                    onChange={() => {
                                      if (formik.values.earlyBirds == 0) {
                                        formik.setFieldValue("earlyBirds", "2");
                                      } else {
                                        formik.setFieldValue("earlyBirds", "0");
                                      }
                                    }}                                  />
                                  <label
                                    className="custom-control-label label-instalments"
                                    htmlFor="10_percent"
                                  >
                                    10%
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>)}

                {/* </> */}
                {/* )} */}
                <div className="row mt-3">
                  <div className="col-12 ">
                    <div className="card-header pt-0">
                      <i
                        className="fal fa-cog"
                        style={{ color: "var(--topbar-color)" }}
                      ></i> Programme Settings
                    </div>
                  </div>

                  <div className={`${formik.values.options_factsheet === 2 ? "col-md-9" : "col-md-6"} col-lg-auto`}>
                    <div className="form-group-blk mb-3" title="This allows you to select how the factsheet should be managed. When none is selected, there will be no fact sheet on the page, when auto generate is selected, the factsheet will be generated from PMC data. When upload is selected, you will be able to upload a pdf file that will be used as the fact sheet.">
                      <label htmlFor="early_bird_discount">Factsheet Options <i className="fal fa-info-circle"></i></label>
                      <div className="d-flex form-group-blk">
                        <div className="custom-control custom-radio text-left mr-3">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="options_factsheet-none"
                            name="options_factsheet"
                            checked={formik.values.options_factsheet === 0}
                            onChange={() => formik.setFieldValue("options_factsheet", 0)}
                          />
                          <label
                            className="custom-control-label label-instalments"
                            htmlFor="options_factsheet-none"
                          >
                            None
                          </label>
                        </div>

                        <div className="custom-control custom-radio text-left mr-3">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="options_factsheet-auto"
                            name="options_factsheet"
                            checked={formik.values.options_factsheet === 1}
                            onChange={() => formik.setFieldValue("options_factsheet", 1)}
                          />
                          <label
                            className="custom-control-label label-instalments text-nowrap"
                            htmlFor="options_factsheet-auto"
                          >
                            Auto Generate
                          </label>
                        </div>

                        <div className="custom-control custom-radio text-left">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="options_factsheet-upload"
                            name="options_factsheet"
                            checked={formik.values.options_factsheet === 2}
                            onChange={() => formik.setFieldValue("options_factsheet", 2)}
                          />
                          <label
                            className="custom-control-label label-instalments"
                            htmlFor="options_factsheet-upload"
                          >
                            Upload
                          </label>
                        </div>
                        {formik.values.options_factsheet !== "10" && formik.values.options_factsheet === 2 && (
                          <FileUploadField label={"Factsheet PDF"} required name={"factsheet_pdf"} formik={formik} accept=".pdf" col_md={4} col_lg={4}  mbTrue={false}/>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={`${formik.values.override_default_tc == 1 ? "col-md-6" : "col-md-6"} col-lg-auto`}>
                    <div className="form-group-blk mb-3">
                      <label htmlFor="early_bird_discount" title="This allows you to select the terms and conditions page for the programme. When default is selected, the terms and conditions assigned to the brand template will be used. When override terms and conditions is selected you will be able to assign a static page that contains custom terms & conditions, the available selection is restricted to pages with the same brand templates assigned.">
                        Terms & Conditions Options <i className="fal fa-info-circle"></i>
                      </label>
                      <div className="d-flex form-group-blk">
                        <div className="custom-control custom-radio text-left">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="override_term_default"
                            name="5_percent"
                            checked={formik.values.override_default_tc == 0}
                            onChange={() => formik.setFieldValue("override_default_tc", 0)}
                          />
                          <label
                            className="custom-control-label label-instalments"
                            htmlFor="override_term_default"
                          >
                            Default
                          </label>
                        </div>

                        <div className="custom-control custom-radio text-left">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="override_term"
                            name="override_default_tc"
                            checked={formik.values.override_default_tc == 1}
                            onChange={() => formik.setFieldValue("override_default_tc", 1)}
                          />
                          <label
                            className="custom-control-label label-instalments"
                            htmlFor="override_term"
                          >
                            Override Terms & Conditions
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formik?.values?.override_default_tc == 1 ? (
                    <div className="col-md-6 col-lg-auto">
                      <div className="d-flex align-items-start flex-column">
                        <label>
                          Assigned Terms & Conditions Page {formik.values.override_default_tc == 1 && "*"} <i className="fal fa-info-circle grade-icon"></i>
                        </label>
                        <button
                          className={
                            "btn btn-save btn-success" +
                            (formik.errors.terms_conditions_page &&
                              formik.touched.terms_conditions_page
                              ? " file-req is-invalid"
                              : "")
                          }
                          type="button"
                          title="Assign Page"
                          data-toggle="modal"
                          data-target="#assignPagePopup"
                        >
                          <i className="fal fa-plus"></i> Assign Page
                        </button>
                        {formik.values.terms_conditions_page
                          ? allpageListData
                            ?.filter(
                              (page) =>
                                page.PageID ===
                                formik.values.terms_conditions_page
                            )
                            .map((assignedPage) => (
                              <div
                                className="frm-group w-100"
                                key={assignedPage.PageID}
                              >
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a
                                      target="_blank"
                                      href={`/website_management/pages/staticpages/table/update/${assignedPage.PageID}`}
                                    >
                                      <span
                                        className="textLimit100 as-text-blue w-100"
                                        title={assignedPage.PageTitle}
                                      >
                                        {TrimText(assignedPage.PageTitle, 30)}
                                      </span>
                                    </a>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => {
                                        formik.setFieldValue(
                                          "terms_conditions_page",
                                          ""
                                        );
                                      }}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  ) : ""}
                  <div
                    className="topic-add-modal modal able_modal01 fade"
                    id="assignPagePopup"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered available-assessment-modal"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header modal-header-custom">
                          <h5 className="modal-title">
                            <i className="fal fa-plus"></i> Assign Page
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <DataTableComponent
                            data={assignPageList}
                            loading={assignPageLoading}
                            state={assignPageTableState}
                            setState={setAssignPageTableState}
                            setSearch={setAssignPageSearch}
                            totalRows={assignPageTotalRows}
                            columns={assignPageColumn}
                            exportFunction={exportAssignPageData}
                            exportFileName={"Assign_Page"}
                            isInsidePopUp={true}
                            filters={[
                              {
                                filterName: "Status",
                                optionArr: PageFilterData.status,
                                state: pageStatus,
                                setState: setPageStatus,
                                renderLabelFunction: LayoutHeaderStatus,
                              },

                              {
                                filterName: "Brand Templates",
                                optionArr: PageFilterData.BrandList,
                                state: pageBrand,
                                isOptionReversed: false,
                                stopOptionSorting: true,
                                setState: setPageBrand,
                                // renderLabelFunction: RenderPageBrands,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-4">
                <PermissionsGate scopes={[type == "create" ? 'wbspagespmcpadd' : 'wbspagespmcpedit']} RenderError={() => (
                  <button className="btn btn-save btn-success" disabled>
                    <i className="fal fa-save"></i> Save
                  </button>
                )}>

                  <button
                    className="btn btn-save btn-success"
                    type="submit"
                    title="Save"
                    disabled={disabled || (type == "open" && !formik.dirty)}
                    onClick={formik.handleSubmit}
                  >
                    {disabled ? (
                      <i className="fas fa-cog fa-spin"></i>
                    ) : (
                      <i className="fal fa-save"></i>
                    )}
                    Save
                  </button>
                </PermissionsGate>
              
                  { formik.values.options_factsheet == 1 && (
                      <button className="btn btn-primary" onClick={handleDownload} type="button" disabled={buttonLoading}>
                        {buttonLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file-alt"></i>}
                        Preview
                      </button>
                    )
                  }

                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() => {
                      if(type =="open" || type == "update"){
                        formik.resetForm()
                        setUpdateData(Date.now())
                      }
                      else{
                        history.push("/websiteManagement/pages/programmes_and_short_courses/programme_pages/table")
                      }
                    }
                    }
                  >
                    <i className="fal fa-times"></i>Cancel
                  </button>
                </div>
                {/** map function to render all errors  */}
                {Object.keys(formik.values).map((key) => {
                  if (formik.touched[key] && formik.errors[key]) {
                    return (
                      <div className="invalid-feedback d-block" key={key}>
                        {formik.errors[key]}
                      </div>
                    );
                  }
                })}
              </form>
            </div>
          </div>}
      </div>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignPagePopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Assign Page
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <DataTableComponent
                data={assignPageList}
                loading={assignPageLoading}
                state={assignPageTableState}
                setState={setAssignPageTableState}
                setSearch={setAssignPageSearch}
                totalRows={assignPageTotalRows}
                columns={assignPageColumn}
                exportFunction={exportAssignPageData}
                exportFileName={"Assign_Page"}
                isInsidePopUp={true}
                filters={[
                  {
                    filterName: "Status",
                    optionArr: PageFilterData.status || [],
                    state: pageStatus,
                    setState: setPageStatus,
                    renderLabelFunction: RenderPMCPageStatus,
                  },

                  {
                    filterName: "Brand Templates",
                    optionArr: PageFilterData.BrandList || [],
                    state: pageBrand,
                      isOptionReversed: false,
                    stopOptionSorting: true,
                    setState: setPageBrand,
                    // renderLabelFunction: RenderPageBrands,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgrammePageDetails;


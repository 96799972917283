import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faSleigh } from "@fortawesome/free-solid-svg-icons";
import { getBlockVariantTypeDropDown, getBrandTemplate, getBrandTemplateStatus, getCTAButtonTypeDropDownList, getHeaderDropDownListStatic, getHeaderDropdown, getPopUpListStatic, updateBrandTemplateHomePage } from "../../../../services/BrandTemplateServices";
import axios from "../../../../utils/axios";
import { AssignPageField, FormField } from "../../../common/FormFields";
import SectionColorOverrideComponent from "../../SectionColorOverrideComponent.jsx";
import HtmlInputEditor from "../../../common/HtmlInputEditor.js";
import { GetAllHeaderPMCpageList, getHeaderDropDownList, getHeadersPageList } from "../../../../services/LayoutHeaderServices.js";
// import AssignPagePopup from "./AssignPagePopup.jsx";
import AssignQualificationPopup from "../../../layoutNavigation/layoutHeaders/AssignQualificationPopup.jsx";
import PermissionsGate from "../../../../utils/permissionGate.js";
import hasPermission from "../../../../utils/hasMultiplePermission.js";
import { useSelector } from "react-redux";
import AssignPagePopup from "../../../layoutNavigation/layoutHeaders/AssignPagePopup.jsx";


const BrandHome = () => {
    const { subId, subType, subTab } = useParams();
    const history = useHistory();
    const [statusArr, setStatusArr] = useState([]);
    const [popUpArr, setPopUpArr] = useState([]);
    const [headerArr, setHeaderArr] = useState([]);
    const [learnignBlockArr, setLearnignBlockArr] = useState([]);
    const [officeNo, setOfficeNo] = useState('');
    const [officeAdd, setOfficeAdd] = useState('');
    const [regOfficeAdd, setRegOfficeAdd] = useState('');
    const [testimonialsBlockArr, setTestimonialsBlockArr] = useState([]);
    const [brandData, setBrandData] = useState({});
    const [ctaButtonTypeData, setCtaButtonTypeData] = useState([])
    const [assignedPopupPages, setAssignedPopupPages] = useState({ id: "", type: "" });
    const [assignedQualification, setAssignedQualification] = useState({ id: "", type: "" });
    const assignedQualificationRef = useRef(assignedQualification);
    const assignedPageRef = useRef(assignedPopupPages);
    const [headerData, setHeaderData] = useState([])
    const [Qualification, setQualification] = useState([])
    const [assignedPage, setAssignedPage] = useState([]);

    useEffect(() => {
        assignedPageRef.current = assignedPopupPages;
    }, [assignedPopupPages]);

    useEffect(() => {
        assignedQualificationRef.current = assignedQualification;
    }, [assignedQualification]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [statusRes, headerRes, BlockVariantTypeRes, PopUpRes, CtaButtonData] = await Promise.all([
                    getBrandTemplateStatus(),
                    getHeaderDropDownListStatic(),
                    getBlockVariantTypeDropDown(),
                    getPopUpListStatic("type=BrandTemplate"),
                    getHeaderDropDownList()
                ]);
                const statusFilters = statusRes.data.statusFilters || [];
                const popUpFilters = PopUpRes.data.Data || [];
                const headerFilters = headerRes.data.Data || [];
                const learnignBlockFilters = BlockVariantTypeRes.data.learnignBlock || [];
                const testimonialsBlockFilters = BlockVariantTypeRes.data.testimonialsBlock || [];
                const ctaButtontypeFilterData = CtaButtonData.data?.headerButtonType
                setStatusArr(statusFilters);
                setHeaderArr(headerFilters);
                setPopUpArr(popUpFilters);
                setLearnignBlockArr(learnignBlockFilters);
                setTestimonialsBlockArr(testimonialsBlockFilters);
                setCtaButtonTypeData(ctaButtontypeFilterData);

            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();
    }, []);
    


    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        let response = hasPermission({ scopes: ["wbssitebrandtmpview"], permissions: givenPermsisions, });
        if (!response) {
            history.push("/noaccess");
        }
    }, [])

    useEffect(() => {
        if (assignedQualification) {
            formik.setFieldValue("assignedPage", {
                id: assignedQualification.id,
                type: assignedQualification.flag,
            });
        }
    }, [assignedQualification]);

    useEffect(() => {
        if (assignedPopupPages && assignedPopupPages.PageID) {
            formik.setFieldValue("assignedPage", {
                id: assignedPopupPages.PageID,
                type: assignedPopupPages.flag,
            });
        }
    }, [assignedPopupPages]);
    useEffect(() => {
        if (assignedQualification) {
            formik.setFieldValue("assignedPage", {
                id: assignedQualification.id,
                type: assignedQualification.flag,
            });
        }
    }, [assignedQualification]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getBrandTemplate(subId);
                setBrandData(res.data?.data);
                setOfficeAdd(res.data?.data?.ctOfficeAddressText);
                setOfficeNo(res.data?.data?.ctOfficeText);
                setRegOfficeAdd(res.data?.data?.ftRegInfoText);
            } catch (error) {
                // if (!axios.isCancel(error)) {
                if (error.response.data?.message !== "") {
                    Swal.fire({
                        title: "Info",
                        text: "No record found with this ID",
                    });
                    history.push(`/websiteManagement/siteSetup/brand_Templates`);
                }
                // }
            }
        };
        if (subId) {
            fetchData();
        }
    }, [subId]);
    useEffect(() => {
        console.log("brandData?.assignedPage", brandData?.assignedPage)
        if (brandData?.ctCTAButtonType == 1) {

            formik.setFieldValue("assignedPage", {
                id: brandData?.assignedPage?.id || "",
                type: brandData?.assignedPage?.flag || "",
            });
            setAssignedQualification(brandData?.assignedPage)
        } else if (brandData?.ctCTAButtonType == 0) {
            formik.setFieldValue("assignedPage", {
                id: brandData?.assignedPage?.id || "",
                type: brandData?.assignedPage?.flag || "",
            });
            setAssignedPopupPages(brandData?.assignedPage)
        }
    }, [brandData])
    console.log("assignedQualification", assignedQualification, assignedPage)
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            siteTitle: brandData.siteTitle || '',
            header: brandData.header || '',
            homePagePopUPID: brandData.homePagePopUPID !== null ? brandData.homePagePopUPID : '',

            analyticsCode: brandData.analyticsCode || '',
            facebook_pixel: brandData.facebook_pixel,
            metaKeywords: brandData.metaKeywords || '',
            metaDecription: brandData.metaDecription || '',

            homePagePrimaryCategoryText: brandData.homePagePrimaryCategoryText || '',
            isHomePagePrimaryCategoryShowBlock: brandData.isHomePagePrimaryCategoryShowBlock || 0,
            isHomePagePrimaryCategorySearchBarShow: brandData.isHomePagePrimaryCategorySearchBarShow || 0,

            homePageSecCategoryText: brandData.homePageSecCategoryText || '',
            isHomePageSecCategoryShowBlock: brandData.isHomePageSecCategoryShowBlock || 0,

            homePageUpcomingText: brandData.homePageUpcomingText || '',
            isHomePageUpcomingShowBlock: brandData.isHomePageUpcomingShowBlock || 0,

            homePageLearningVariant: brandData.homePageLearningVariant || '',
            homePageLearningText: brandData.homePageLearningText || '',
            isHomePageLearningShowBlock: brandData.isHomePageLearningShowBlock || 0,

            homePageTestimonialsVariant: brandData.homePageTestimonialsVariant || '',
            homePageTestimonialsText: brandData.homePageTestimonialsText || '',
            isHomePageTestimonialsShowBlock: brandData.isHomePageTestimonialsShowBlock || 0,

            homePageNewsText: brandData.homePageNewsText || '',
            isHomePageNewsShowBlock: brandData.isHomePageNewsShowBlock || 0,

            school_override_color: brandData?.school_override_color || 0,
            school_accentColor: brandData?.school_accentColor || "#007BFF",
            school_textColorOne: brandData?.school_textColorOne || "#007BFF",
            school_textColorTwo: brandData?.school_textColorTwo || "#007BFF",
            school_backgroundColor: brandData?.school_backgroundColor || "#007BFF",

            openday_override_color: brandData?.openday_override_color || 0,
            openday_accentColor: brandData?.openday_accentColor || "#007BFF",
            openday_textColorOne: brandData?.openday_textColorOne || "#007BFF",
            openday_textColorTwo: brandData?.openday_textColorTwo || "#007BFF",
            openday_backgroundColor: brandData?.openday_backgroundColor || "#007BFF",

            learning_method_override_color: brandData?.learning_method_override_color || 0,
            learning_method_accentColor: brandData?.learning_method_accentColor || "#007BFF",
            learning_method_textColorOne: brandData?.learning_method_textColorOne || "#007BFF",
            learning_method_textColorTwo: brandData?.learning_method_textColorTwo || "#007BFF",
            learning_method_backgroundColor: brandData?.learning_method_backgroundColor || "#007BFF",

            testimonials_override_color: brandData?.testimonials_override_color || 0,
            testimonials_accentColor: brandData?.testimonials_accentColor || "#007BFF",
            testimonials_textColorOne: brandData?.testimonials_textColorOne || "#007BFF",
            testimonials_textColorTwo: brandData?.testimonials_textColorTwo || "#007BFF",
            testimonials_backgroundColor: brandData?.testimonials_backgroundColor || "#007BFF",

            news_override_color: brandData?.news_override_color || 0,
            news_accentColor: brandData?.news_accentColor || "#007BFF",
            news_textColorOne: brandData?.news_textColorOne || "#007BFF",
            news_textColorTwo: brandData?.news_textColorTwo || "#007BFF",
            news_backgroundColor: brandData?.news_backgroundColor || "#007BFF",
            groupByCourseLandingPages: brandData?.groupByCourseLandingPages || 0,
            // footer_accentColor: brandData?.footer_accentColor || "#007BFF",
            // footer_textColorOne: brandData?.footer_textColorOne || "#007BFF",
            // footer_textColorTwo: brandData?.footer_textColorTwo || "#007BFF",
            // footer_backgroundColor: brandData?.footer_backgroundColor || "#007BFF",

            ctShowBlock: brandData?.ctShowBlock || 0,
            ctShowForm: brandData?.ctShowForm || 0,
            ctShowDetails: brandData?.ctShowDetails || 0,
            ctTitleShow: brandData?.ctTitleShow || 0,
            ctCTAShowSection: brandData?.ctCTAShowSection || 0,
            ctCTAShowButton: brandData?.ctCTAShowButton || 0,
            ctOfficeShowSection: brandData?.ctOfficeShowSection || 0,
            ctOfficeShowAddress: brandData?.ctOfficeShowAddress || 0,
            ftShowBlock: brandData?.ftShowBlock || 0,
            ftPrimaryAddShowBlock: brandData?.ftPrimaryAddShowBlock || 0,
            ftRegInfoShowBlock: brandData?.ftRegInfoShowBlock || 0,
            // assignedPageId: brandData?.assignedPageId || "",
            // assignedPageType: brandData?.assignedPageType || "",
            assignedPage: brandData?.assignedPage || {},

            ctTitleText: brandData?.ctTitleText || "",
            ctCATFontIcon: brandData?.ctCATFontIcon || "",
            // ctCTAText: brandData?.ctCTAText || "",
            ctCTAButtonText: brandData?.ctCTAButtonText || "",
            ctCTAButtonType: brandData?.ctCTAButtonType || "",
            ctCTAButtonUrl: brandData?.ctCTAButtonUrl || "",
            ctOfficeText: brandData?.ctOfficeText || "",
            ctOfficeAddressText: brandData?.ctOfficeAddressText || "",

            ctoverride_color: brandData?.ctoverride_color || 0,
            ctAccentColour: brandData?.ctAccentColour || "#007BFF",
            ctTextColorOne: brandData?.ctTextColorOne || "#007BFF",
            ctTextColorTwo: brandData?.ctTextColorTwo || "#007BFF",
            ctBackgroundColor: brandData?.ctBackgroundColor || "#007BFF",
            ctCTACallAction: brandData?.ctCTACallAction || "",
            ftHighlightInfo: brandData?.ftHighlightInfo || "",
            ftPrimaryAddText: brandData?.ftPrimaryAddText || "",
            ftRegInfoText: brandData?.ftRegInfoText || "",

            ftoverride_color: brandData?.ftoverride_color || 0,
            ftAccentColour: brandData?.ftAccentColour || "#007BFF",
            ftTextColorOne: brandData?.ftTextColorOne || "#007BFF",
            ftTextColorTwo: brandData?.ftTextColorTwo || "#007BFF",
            ftBackgroundColor: brandData?.ftBackgroundColor || "#007BFF",

        },
        validationSchema: Yup.object().shape({
            header: Yup.string().required('Header is required').nullable().trim(),
            assignedPage: Yup.object().when('ctCTAButtonType', {
                is: 1, // When buttonType is 1
                then: Yup.object().shape({
                    id: Yup.string().required("Assigned Qualification for Button is required"),
                    type: Yup.string().required("Assigned Qualification Type is required"),
                }),
                otherwise: Yup.object().when('ctCTAButtonType', {
                    is: 0, // When buttonType is 0
                    then: Yup.object().shape({
                        id: Yup.string().required("Assigned Page for Button is required"),
                        type: Yup.string().required("Assigned Page Type is required"),
                    }),
                    otherwise: Yup.object().nullable(), // For other button types, make it nullable
                }),
            }),
            ctCTAButtonUrl: Yup.string().matches(/^(\/|https?:\/\/)/, "URL must start with / or a valid protocol").when("ctCTAButtonType", {
                is: 2,
                then: Yup.string().required("Custom Link is required"),
            }),
            // homePagePopUPID: Yup.string().required('PopUP is required'),
            // analyticsCode: Yup.string(),
            // metaKeywords: Yup.string(),
            // metaTitle: Yup.string(),
            // metaDecription: Yup.string(),
            //homePagePrimaryCategoryText: Yup.string().required('Schools Category Section Title Text is required').nullable().trim(),
            //homePageSecCategoryText: Yup.string().required('Secondary Category Section Title Text is required').nullable().trim(),
            //homePageUpcomingText: Yup.string().required('Upcoming Open Days Section Title Text is required').nullable().trim(),
            //homePageLearningText: Yup.string().required('Learning Methods Section Title Text is required').nullable().trim(),
            //homePageTestimonialsText: Yup.string().required('Testimonials Section Title Text is required').nullable().trim(),
            //homePageNewsText: Yup.string().required('News Section Title Text is required').nullable().trim(),

        }),
        onSubmit: async (values, { setSubmitting }) => {

            if (subType === 'Create') {
                Swal.fire('Error!', 'Something went wrong', 'error');
            } else if (subType == 'update') {
                const temp = { ...values }
                temp.ftRegInfoText = regOfficeAdd
                temp.ctOfficeAddressText = officeAdd
                temp.ctOfficeText = officeNo

                if (temp.ctCTAButtonType == 2) {
                    delete temp.assignedPage;
                }
                if (temp.ctCTAButtonType == 1) {
                    delete temp.ctCTAButtonUrl;
                }
                if (temp.ctCTAButtonType == 0) {
                    delete temp.ctCTAButtonUrl;
                }
                const formData = new FormData();

                // Prepare values
                const cleanedValues = Object.fromEntries(
                    Object.entries(temp).map(([key, value]) => [
                        key,
                        value === undefined || value === null ? "" : value
                    ])
                );

                console.log(">>>>>>formik", formik.values.assignedPage)
                // Handle specific field 'assignedPage'
                if (cleanedValues.assignedPage && typeof cleanedValues.assignedPage === 'object') {
                    formData.append('assignedPageId', cleanedValues.assignedPage.id || "");
                    formData.append('assignedPageType', cleanedValues.assignedPage.type ?? "");
                }

                // Append other fields to FormData
                Object.keys(cleanedValues).forEach((key) => {
                    if (key !== 'assignedPage') {
                        const value = cleanedValues[key];
                        if (Array.isArray(value)) {
                            value.forEach((val, idx) => {
                                formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
                            });
                        } else {
                            formData.append(key, value);
                        }
                    }
                });
                formData.append("brandID", subId);
                await updateBrandTemplateHomePage(formData)
                    .then(response => {
                        Swal.fire('Success!', 'Brand Template updated successfully!', 'success');
                        // history.push("/websiteManagement/siteSetup/brand_Templates");
                        console.log('buttonType------------>', temp)

                    })
                    .catch(error => {
                        Swal.fire('Error!', 'Failed to update Brand Template', 'error');
                    });
            }
            setSubmitting(false);
        },
    });
    const { handleSubmit } = formik

    const handleCallback = () => {
        formik.setFieldValue("assignedPage", "")
        formik.setFieldValue("ctCTAButtonUrl", "")
        setAssignedQualification({ id: "", type: "" })
        setAssignedPopupPages({ id: "", type: "" })

    }

    useEffect(() => {
        const assingedQualification = () => {
            GetAllHeaderPMCpageList(subTab == "update" && subId)
                .then(res => {
                    setQualification(res.data?.data)
                })
                .catch(err => console.log(err))
        }

        const assignedPage = () => {
            getHeadersPageList(subTab == "update" && subId)
                .then(res => {
                    setHeaderData(res.data?.data)
                })
                .catch(err => console.log(err))
        }
        if (formik.values.ctCTAButtonType == 0) {
            assignedPage();
        }
        if (formik.values.ctCTAButtonType == 1) {
            assingedQualification();
        }
    }, [formik.values.ctCTAButtonType])
    return (
        <div className="card card-body-inr">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Home Page Information</h4>
                        </div>
                    </div>
                    <FormField field={{ name: "header", label: "Default Header" }} formik={formik} selectOptions={headerArr} />
                    <FormField field={{ name: "homePagePopUPID", label: "Popup" }} formik={formik} selectOptions={popUpArr} />
                    <div className="col-md-12">
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Our Schools Section</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Section Settings"}>
                            Section Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePagePrimaryCategoryShowBlock"
                                    name="isHomePagePrimaryCategoryShowBlock"
                                    checked={formik.values.isHomePagePrimaryCategoryShowBlock == 1}
                                    onChange={(e) => {
                                        console.log("Updating isHomePagePrimaryCategoryShowBlock:", e.target.checked);
                                        formik.setFieldValue(
                                            "isHomePagePrimaryCategoryShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePagePrimaryCategoryShowBlock"
                                >
                                    Show Section
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Course Search Bar">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePagePrimaryCategorySearchBarShow"
                                    name="isHomePagePrimaryCategorySearchBarShow"
                                    checked={formik.values.isHomePagePrimaryCategorySearchBarShow == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePagePrimaryCategorySearchBarShow",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePagePrimaryCategorySearchBarShow"
                                >
                                    Show Course Search Bar
                                </label>
                            </div>
                        </div>
                    </div>
                    <FormField field={{ name: "homePagePrimaryCategoryText", label: "Section Title Text" }} formik={formik} col_md={6}
                        col_lg={9} />
                    <div className="col-md-12">
                        <div
                            className="d-flex my-20"
                            title="Group Content by Course Landing Pages and not by schools"
                        >
                            <label className="mb-0">
                                Group Content by Course Landing Pages
                                <i className="fal fa-info-circle grade-icon ml-2"></i>
                                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
                            </label>
                            <div className="toggle-switch ml-2">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={formik.values.groupByCourseLandingPages == 1}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'groupByCourseLandingPages',
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                    />
                                    <span className="slider slider-round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <SectionColorOverrideComponent
                            prefix="school_"
                            formik={formik}
                            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />

                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>

                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Secondary Category Section</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Section Settings"}>
                            Section Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageSecCategoryShowBlock"
                                    name="isHomePageSecCategoryShowBlock"
                                    checked={formik.values.isHomePageSecCategoryShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageSecCategoryShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageSecCategoryShowBlock"
                                >
                                    Show Section
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageSecCategoryText", label: "Section Title Text" }} formik={formik} col_md={6}
                        col_lg={9} />
                    <div className="col-md-12">
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Upcoming Open Days Section</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Section Settings"}>
                            Section Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageUpcomingShowBlock"
                                    name="isHomePageUpcomingShowBlock"
                                    checked={formik.values.isHomePageUpcomingShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageUpcomingShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageUpcomingShowBlock"
                                >
                                    Show Section
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageUpcomingText", label: "Section Title Text" }} formik={formik} col_md={6}
                        col_lg={9} />
                    <div className="col-md-12">
                        <SectionColorOverrideComponent
                            prefix="openday_"
                            formik={formik}
                            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Learning Methods Section</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Section Settings"}>
                            Section Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageLearningShowBlock"
                                    name="isHomePageLearningShowBlock"
                                    checked={formik.values.isHomePageLearningShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageLearningShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageLearningShowBlock"
                                >
                                    Show Section
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageLearningVariant", label: "Section Variant", required: false }} required={false} selectOptions={learnignBlockArr} formik={formik} col_md={6}
                        col_lg={3} />
                    <FormField field={{ name: "homePageLearningText", label: "Section Title Text" }} formik={formik} col_md={6}
                        col_lg={6} />
                    <div className="col-md-12">
                        <SectionColorOverrideComponent
                            prefix="learning_method_"
                            formik={formik}
                            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Testimonials Section</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Section Settings"}>
                            Section Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageTestimonialsShowBlock"
                                    name="isHomePageTestimonialsShowBlock"
                                    checked={formik.values.isHomePageTestimonialsShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageTestimonialsShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageTestimonialsShowBlock"
                                >
                                    Show Section
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageTestimonialsVariant", label: "Section Variant", required: false }} required={false} selectOptions={testimonialsBlockArr} formik={formik} col_md={6}
                        col_lg={3} />
                    <FormField field={{ name: "homePageTestimonialsText", label: "Section Title Text" }} formik={formik} col_md={6}
                        col_lg={6} />
                    <div className="col-md-12">
                        <SectionColorOverrideComponent
                            prefix="testimonials_"
                            formik={formik}
                            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>


                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">News Section</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Section Settings"}>
                            Section Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageNewsShowBlock"
                                    name="isHomePageNewsShowBlock"
                                    checked={formik.values.isHomePageNewsShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageNewsShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageNewsShowBlock"
                                >
                                    Show Section
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageNewsText", label: "Section Title Text" }} formik={formik} col_md={6}
                        col_lg={9} />
                    <div className="col-md-12">
                        <SectionColorOverrideComponent
                            prefix="news_"
                            formik={formik}
                            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header"><FontAwesomeIcon icon={faGear} color="var(--topbar-color)" /> SEO Settings</h4>
                        </div>
                    </div>
                    <FormField field={{ name: "analyticsCode", label: "Analytics Code", required: false }} formik={formik} required={false} />
                    <FormField field={{ name: "metaTitle", label: "Meta Title", required: false }} formik={formik} required={false} />
                    <FormField field={{ name: "metaDecription", label: "Meta Description", required: false }} formik={formik} required={false} />
                    <FormField field={{ name: "metaKeywords", label: "Meta Keywords", required: false }} formik={formik} required={false} />
                    <FormField field={{ name: "facebook_pixel", label: "Facebook Pixel", required: false }} formik={formik} required={false} />
                </div>
                <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />

                <div className="col-md-12 p-0" >
                    <div className="edit-icon new-card-header">
                        <h4 className="card-header">Contact Us Block</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <label htmlFor={"SectionSettings"}>Block Settings</label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ctShowBlock"
                                    name="ctShowBlock"
                                    checked={formik.values.ctShowBlock}
                                    onChange={(e) => formik.setFieldValue('ctShowBlock', e.target.checked ? 1 : 0)}
                                /> <label className="custom-control-label" htmlFor="ctShowBlock">Show Block</label>
                            </div>

                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Form">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ctShowForm"
                                    name="ctShowForm"
                                    checked={formik.values.ctShowForm}
                                    onChange={(e) => formik.setFieldValue('ctShowForm', e.target.checked ? 1 : 0)}
                                />
                                <label className="custom-control-label" htmlFor="ctShowForm">Show Form</label>
                            </div>

                            {/*  <div className="custom-control custom-checkbox text-left mb-3 ml-3" title="Show Details">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ctShowDetails"
                                    name="ctShowDetails"
                                    checked={formik.values.ctShowDetails}
                                    onChange={(e) => formik.setFieldValue('ctShowDetails', e.target.checked ? 1 : 0)}
                                />
                                <label className="custom-control-label" htmlFor="ctShowDetails">Show Details</label>
                            </div> */}

                        </div>
                    </div>
                    <FormField
                        field={{ name: "ctTitleText", label: "Block Title Text" }}
                        required={false}
                        formik={formik}
                        col_md={6}
                        col_lg={9}
                    />
                </div>
                <div className="col-md-12 p-0">
                    <SectionColorOverrideComponent
                        prefix="ct"
                        formik={formik}
                        col_lg={12}
                        required={false}
                        margin={false}
                        fields={["AccentColour", "TextColorOne", "TextColorTwo", "BackgroundColor"]} />
                    {/* <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} /> */}
                </div>

                {/* <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <label htmlFor="SectionTitleSection">Section Title Settings</label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Title Section">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ctTitleShow"
                                    name="ctTitleShow"
                                    checked={formik.values.ctTitleShow}
                                    onChange={(e) => formik.setFieldValue('ctTitleShow', e.target.checked ? 1 : 0)}
                                />
                                <label className="custom-control-label" htmlFor="ctTitleShow">
                                    Show Title Section
                                </label>
                            </div>
                        </div>
                    </div>

                  
                    <div className="col-md-6 col-lg-9">
                    </div> 
                </div> */}

                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <div className="col-md-12 p-0">
                            <div className="edit-icon new-card-header">
                                <h4 className="card-header">
                                    <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" /> CTA Section
                                </h4>
                            </div>
                        </div>

                        <div className="form-icon-group ">
                            <div className="custom-control custom-checkbox text-left  mr-3" title="Show Section">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ctCTAShowSection"
                                    name="ctCTAShowSection"
                                    checked={formik.values.ctCTAShowSection}
                                    onChange={(e) => formik.setFieldValue('ctCTAShowSection', e.target.checked ? 1 : 0)}
                                />
                                <label className="custom-control-label" htmlFor="ctCTAShowSection">Show Section</label>
                            </div>

                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Button">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ctCTAShowButton"
                                    name="ctCTAShowButton"
                                    checked={formik.values.ctCTAShowButton}
                                    onChange={(e) => formik.setFieldValue('ctCTAShowButton', e.target.checked ? 1 : 0)}
                                />
                                <label className="custom-control-label" htmlFor="ctCTAShowButton">Show Button</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-12">


                        <div className="row">
                            <FormField
                                field={{ name: "ctCTACallAction", label: "Call to Action Text", required: false, info: 'true' }}
                                required={false}
                                formik={formik}
                                col_md={6}
                                col_lg={4}
                            />
                            <FormField
                                field={{ name: "ctCATFontIcon", label: "Font Awesome Icon Class Name", required: false, info: 'true' }}
                                required={false}
                                formik={formik}
                                col_md={6}
                                col_lg={4}
                            />
                            {/* <FormField
                            field={{ name: "ctCTAText", label: "CTA Text", required: false }}
                            formik={formik}
                            col_md={6} col_lg={4}
                        /> */}
                            <FormField
                                field={{ name: "ctCTAButtonText", label: "Button Text", required: false }}
                                required={false}
                                formik={formik}
                                col_md={6} col_lg={4}
                            />
                            <FormField
                                field={{ name: "ctCTAButtonType", label: "CTA Button Type", required: false, info: 'true' }}
                                required={false} selectOptions={ctaButtonTypeData}
                                formik={formik}
                                col_md={6} col_lg={4}
                                callback={handleCallback}
                            />

                            {
                                formik.values.ctCTAButtonType == 2 ? <FormField
                                    field={{ name: "ctCTAButtonUrl", label: "Custom Link URL", required: false, info: 'true' }}
                                    required={false}
                                    formik={formik}
                                    col_md={6}
                                    col_lg={3}
                                    placeholder="Enter Custom Link URL"
                                />
                                    :
                                    formik.values.ctCTAButtonType == 0 ? <AssignPageField name="assignedPage" label="Assigned Page for Button*" formik={formik} dataTarget="#assignPagePopupBrandTemp" assignedPage={assignedPopupPages}
                                        setAssignedPage={setAssignedPopupPages} />
                                        :
                                        formik.values.ctCTAButtonType == 1 ? <AssignPageField name="assignedPage" label="Assigned Qualification for Button *" formik={formik} dataTarget="#assignQualificationPopup" assignedPage={assignedQualification}
                                            setAssignedPage={setAssignedQualification} buttonName="Assign Qualification" />
                                            :
                                            null
                            }
                        </div>
                    </div>
                    {/* <FormField field={{ name: "footerButtonText", label: "Button Text", required: false }} formik={formik} required={false} />
                    <FormField field={{ name: "footerDropDownList", label: "CTA Button type", required: false }} formik={formik} required={false} /> */}
                </div>
                <div className="row">
                    <div className="col-md-12 p-0">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header ml-3">
                                <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" /> Office Details Section
                            </h4>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                        <div className="form-icon-group mb-3 ">
                            <div className="custom-control custom-checkbox text-left " title="Show Section">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ctOfficeShowSection"
                                    name="ctOfficeShowSection"
                                    checked={formik.values.ctOfficeShowSection}
                                    onChange={(e) => formik.setFieldValue('ctOfficeShowSection', e.target.checked ? 1 : 0)}
                                />
                                <label className="custom-control-label" htmlFor="ctOfficeShowSection">Show Sections</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-12">
                        <label htmlFor="ctaSection">Office Numbers</label>
                        <HtmlInputEditor
                            editorState={officeNo}
                            setEditorState={setOfficeNo}
                            hideSign={true}
                            belowNoMargin={true}
                            isCKEditor={true}
                            cameFrom={'CTA_section'}
                        />
                    </div>
                    {/* <FormField field={{ name: "footerButtonText", label: "Button Text", required: false }} formik={formik} required={false} />
                    <FormField field={{ name: "footerDropDownList", label: "CTA Button type", required: false }} formik={formik} required={false} /> */}
                </div>
                <div className="row mt-4">
                    <div className="col-md-6 col-lg-3">
                        <div className="form-icon-group  mt-2"> <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Section"> <input type="checkbox" className="custom-control-input"
                            id="ctOfficeShowAddress"
                            name="ctOfficeShowAddress"
                            checked={formik.values.ctOfficeShowAddress}
                            onChange={(e) => formik.setFieldValue('ctOfficeShowAddress', e.target.checked ? 1 : 0)}
                        />
                            <label className="custom-control-label" htmlFor="ctOfficeShowAddress">Show Section</label>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-12">
                        <label htmlFor="ctaSection">Office Addresses</label>
                        <HtmlInputEditor
                            editorState={officeAdd}
                            setEditorState={setOfficeAdd}
                            hideSign={true}
                            belowNoMargin={true}
                            isCKEditor={true}
                            cameFrom={'CTA_section'}
                         
                        />
                    </div>

                </div>
                <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />

                {/* <div className="col-md-12 p-0">
                    <SectionColorOverrideComponent
                        prefix="ct"
                        formik={formik}
                        col_lg={12}
                        required={false}
                        margin={false}
                        fields={["AccentColour", "TextColorOne", "TextColorTwo", "BackgroundColor"]} />
                    <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                </div> */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Footer Block</h4>
                        </div>
                    </div>

                    <FormField
                        field={{ name: "ftHighlightInfo", label: "Highlight Information" }}
                        formik={formik}
                        col_md={6}
                        col_lg={6}
                        required={false}
                    />

                    <FormField
                        field={{ name: "ftPrimaryAddText", label: "Primary Address" }}
                        formik={formik}
                        col_md={6}
                        col_lg={6}
                        required={false}
                    />


                    <div className="col-md-6 col-lg-12">
                        <label>Regulatory Information </label>
                        <HtmlInputEditor
                            editorState={regOfficeAdd}
                            setEditorState={setRegOfficeAdd}
                            hideSign={true}
                            belowNoMargin={true}
                            isCKEditor={true}
                            cameFrom={'CTA_section'}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <SectionColorOverrideComponent
                            prefix="ft"
                            formik={formik}
                            col_lg={12}
                            margin={false}
                            required={false}
                            fields={["AccentColour", "TextColorOne", "TextColorTwo", "BackgroundColor"]} />
                    </div>
                </div>


                <div className="form-group form-group-save-cancel">
                    <PermissionsGate scopes={[subType == "create" ? 'wbssitebrandtmpadd' : 'wbssitebrandtmpedit']} RenderError={() => (
                        <button className="btn btn-save btn-primary" disabled>
                            <i className="fal fa-save"></i> Save
                        </button>
                    )}>
                        <button className="btn btn-save btn-success" disabled={formik.isSubmitting} type="submit" title="Save">
                            {formik.isSubmitting ? (
                                <i className="fas fa-cog fa-spin"></i>
                            ) : (
                                <i className="fal fa-save"></i>
                            )} Save
                        </button>
                    </PermissionsGate>
                    {Object.keys(formik.errors).map((key) => {
                        if (formik.touched[key]) {
                            const error = formik.errors[key];

                            // Check if it's the assignedPage error and handle it
                            if (key === 'assignedPage') {
                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {error.id ? error.id : error.type ? error.type : "Invalid input"}
                                    </div>
                                );
                            }

                            // For other fields
                            const errorMessage = typeof error === 'string' ? error : error.message || "Invalid input";

                            return (
                                <div className="invalid-feedback d-block" key={key}>
                                    {errorMessage}
                                </div>
                            );
                        }
                        return null;
                    }
                    )}
                </div>
            </form>
            <div
                className="topic-add-modal modal able_modal01 fade"
                id="assignPagePopupBrandTemp"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered available-assessment-modal"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-custom">
                            <h5 className="modal-title">
                                <i className="fal fa-file"></i> Assign Page
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <AssignPagePopup headerData={headerData} formik={formik} id={subId} assignedPage={assignedPopupPages} assignedPageRef={assignedPageRef} setAssignedPage={setAssignedPopupPages} />
                            {/* <AssignPagePopup headerData={headerData} formik={formik} id={subId} assignedPage={assignedPopupPages} assignedPageRef={assignedPageRef} setAssignedPage={setAssignedPopupPages} paginationServer={false} /> */}
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="topic-add-modal modal able_modal01 fade"
                id="assignQualificationPopup"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered available-assessment-modal"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-custom">
                            <h5 className="modal-title">
                                <i className="fal fa-file"></i> Assign Qualification
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <AssignQualificationPopup formik={formik} id={subId} assignedPage={assignedQualification} setAssignedPage={setAssignedQualification} assignedQualificationRef={assignedQualificationRef} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandHome;
